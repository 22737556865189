import React from "react";
import "./ContactUs.css";
import Navbar from "./Navbar";
import Typed from "typed.js";
import { fs } from "../firebase";
import { collection, addDoc } from "firebase/firestore";
import { useRef, useEffect, useState } from "react";
import { async } from "@firebase/util";
import Footer from "./Footer";
import { AiOutlineMail } from "react-icons/ai";
import { VscLocation } from "react-icons/vsc";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ContactUS = () => {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [certification, setCertification] = useState("");
  const [phone, setPhone] = useState("");
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [topicOfInterest, setTopicOfInterest] = useState("");
  const [successMsg, setSuccessmsg] = useState("");
  const [error, setError] = useState("");
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);

  const notify = () => {

    toast.success("Success Notification !", {
      position: toast.POSITION.TOP_CENTER
    });}

  const addDeatailsToForm = async (e) => {
    e.preventDefault();
    // console.log(fname,lname,email,certification,phone,time,date,topicOfInterest)

    notify();
    const docRef = await addDoc(collection(fs, "newSpeaker"), {
      fname,
      lname,
      email,
      
      phone,
      
      date,
      
    })
      .then(() => {
        setSuccessmsg(
          "Thanks! for filling the form, our team will reachout to you as per your prefrences"
        );
        setFname("");
        setLname("");
        setEmail("");
        setPhone("");
        
        setDate("");
       
        setError("");
        setTimeout(() => {
          setSuccessmsg("");
        }, 3000);
      })
      .catch((error) => setError(error.message));
  };

  return (
    <div className="contactUsFormContainer1">
    <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
{/* Same as */}
<ToastContainer />
      <div className="navbarContainerForContactUs">
        <Navbar />
      </div>
      <div className="ContactUsFormPageMainHeading">
        <h1 className="ContactUsFormPageMainHeadingActual">
          Become a speaker with us
        </h1>
      </div>
      <div className="contactUsFormContainer">
        {/*  <div className="leftInfoForForm">
          <div className="contactUsFormLeftInfo">
            <h1 className="contactUsFormLeftHeading">
              Become a speaker with us.
            </h1>
            <p className="contactUsFormLeftHeadingInfo">
              Our team will reach back to you:
            </p>
            {successMsg && 
            <div className="successMaGCintainer">
            <br></br>
            <div className="successMsgContactUs">{successMsg}</div>
            
            </div>
          }
          </div>
        </div>
        <div className="rightForm" >
          <div className="firstAndLastNameDiv">
            <div className="firsNameAndInput">
              <p className="beforeInput">First Name:</p>
              <input
                type="text"
                placeholder="First Name"
                className="firstNameInput"
                onChange={(e) => setFname(e.target.value)}
                value={fname}
                required
              />
            </div>
            <div className="lastNmaeAndInput">
              <p className="beforeInputLastName">Last Name:</p>
              <input
                type="text"
                placeholder="Last Name"
                className="LastNameInput"
                required
                onChange={(e) => setLname(e.target.value)}
                value={lname}
              />
            </div>
          </div>
          <div className="emailaddressContactForm">
            <div className="emailAndInput">
              <p className="beforeInput">Email:</p>

              <input
                type="email"
                placeholder="Enter your email"
                className="emailAdressInputContactForm"
                required
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
          </div>

          <div className="certificationContactForm">
            <div className="certificationAndInput">
              <p className="beforeInputCertification">Certification:</p>
              <input
                type="text"
                placeholder=""
               
                className="certificationInputContactForm"
                required
                onChange={(e) => setCertification(e.target.value)}
                value={certification}
              />
            </div>
          </div>

          <div className="emailaddressContactForm">
            <div className="emailAndInput">
              <p className="beforeInput">Phone:</p>
              <input
                type="text"
                placeholder="Enter your phone number"
                className="emailAdressInputContactForm"
                required
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
              />
            </div>
          </div>

          <div className="timeContactForm">
            <div className="timeAndInput">
              <p className="divAboveTimeAndDateInput">
                Best time to reach you:
              </p>
              <div className="timeDatePInputAllTogetherDiv">
                <p className="beforetimeInput">Time and Date:</p>
                <input
                  type="time"
                  placeholder="Enter the time and date to contact!"
                  className="timeInputContactForm"
                  required
                  onChange={(e) => setTime(e.target.value)}
                value={time}
                />
                <input
                  type="date"
                  placeholder="Enter the time and date to contact!"
                  className="dateInputContactForm"
                  required
                  min="2023-02-20" 
                  max="2023-03-28" 
                  onChange={(e) => setDate(e.target.value)}
                value={date}
                />
              </div>
            </div>
          </div>
          <div className="educationtypeContactForm">
            <div className="educationtypeAndInput">
              <p className="beforeInputeducationtype">Topic of interest:</p>
              <input
                type="text"
                placeholder=""
            
                className="educationtypeInputContactForm"
                required
                onChange={(e) => setTopicOfInterest(e.target.value)}
                value={topicOfInterest}
              />
            </div>
          </div>

          <div className="submitContactForm">
            <button type="submit" className="submitButtonContactForm" onClick={addDeatailsToForm}>
              Submit
            </button>
          </div>
        </div>*/}
        <div className="WholeLeftAndRightForm">
          <div className="newLeftSecOfContactUsForm">
            <div className="newLeftFormHeading1">
              <h3 className="newLeftFormFirstHeading">Contact Information</h3>
              <h4 className="newLeftFormFirstHeadingInfo">
                Fill up the form and our team will get back to you.
              </h4>
            </div>

            <div className="newLeftFormHeading">
              <AiOutlineMail className="mailIconForContactUsForm" />
              <p className="mailContacUsInfo">kpreet@lmsrcm.com</p>
            </div>
            <div className="newLeftFormHeading2">
              <VscLocation className="locationconForContactUsForm" />
              <p className="adressContacUsInfo">
                8 The Green Suite,8068<br></br>
                Dover,Delaware<br></br>
                19901
              </p>
            </div>
         
          </div>

          <div className="newRightSecOfContactUsForm">
            <div className="newFirstAndLastNameSec1">
              <div className="newFirstNameSec">
                <p className="newLeftFirstNameHeadingInfo" >First Name</p>
                <input
                  type="text"
                  onChange={(e) => setFname(e.target.value)}
                  value={fname}
                  required
                  className="newInputForFirstName"
                  
                />
              </div>
              <div className="newLastNameSec">
                <p className="newLeftFirstNameHeadingInfo">Last Name</p>
                <input
                  type="text"
                  onChange={(e) => setLname(e.target.value)}
                  value={lname}
                  required
                  className="newInputForFirstName"
                />
              </div>

            </div>
            <div className="newFirstAndLastNameSec">
            <div className="newFirstNameSec">
              <p className="newLeftFirstNameHeadingInfo">Email</p>
              <input
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
                className="newInputForFirstName"
              />
            </div>
            <div className="newLastNameSec">
              <p className="newLeftFirstNameHeadingInfo">Phone</p>
              <input
                type="text"
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
                required
                className="newInputForFirstName"
              />
            </div>

          </div>
          <div className="newFirstAndLastNameSec">
          <div className="newFirstNameSec">
            <p className="newLeftFirstNameHeadingInfo">Best Time to reach you:</p>
         
          </div>
          <div className="newLastNameSec">
           
            <input
              type="datetime-local"
              onChange={(e) => setDate(e.target.value)}
              value={date}
              min="2023-03-14" 
               max="2023-03-24" 
              required
              className="newInputForFirstName"
            />
          </div>

        </div>
        <div className="submitContactForm">
        <button type="submit" className="submitButtonContactForm" onClick={addDeatailsToForm}>
          Submit
        </button>
      </div>
          </div>
        </div>
      </div>
      {error && (
        <div>
          <br></br>
          <div className="error-msg">{error}</div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default ContactUS;
