import {
  Routes,
  Route,
 
} from "react-router-dom";
import Thankyou from "./pages/Thankyou";
import Homepage from "./pages/Homepage";
import Signup from "./pages/Signup";
import Screenshots from "./components/Screenshots";
import ContactUS from "./components/ContactUS";
import Privacypolicy from "./pages/Privacypolicy";
import TrainingTermsOfUse from './pages/TrainingTermsOfUse';
import Unsubscribe from "./pages/Unsubscribe";
import Webinar from "./pages/Webinar";
import IcdTenWebinar from "./pages/IcdTenWebinar";
import SDOH from './pages/SDOH'
import SDOH2 from './pages/SDOH2'

import CDINurses2 from './pages/CDINurses2'
import ICD10 from './pages/ICD10'
import ICD102 from './pages/ICD102'

function App() {


  return (
    <Routes>
      <Route path="/" element={<Homepage/>} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/contactUs" element={<ContactUS />} />
      <Route path="/privacyPolicy" element={<Privacypolicy />} />
      <Route path="/trainingTermsOfUse" element={<TrainingTermsOfUse/>} />
      <Route path="/unsubscribe" element={<Unsubscribe/>} />
      <Route path="/sdohs" element={<Webinar/>} />
      <Route path="/icd10" element={<IcdTenWebinar/>} />
      <Route path="/cdinurses" element={<CDINurses2/>} />
      <Route path="/thankyou" element={<Thankyou/>} />
      <Route path="/sdoh2" element={<SDOH/>} />
      <Route path="/icd-102" element={<ICD10/>} />
      <Route path="/icd-10" element={<ICD102/>} />
      <Route path="/sdoh" element={<SDOH2/>} />













    </Routes>
  );
}
export default App;
