import React from 'react'
import Footer from '../components/Footer'
import './Unsubscribe.css'
import Navbar from '../components/Navbar'
const Unsubscribe = () => {
  return (<>
    <div className="navbarContainer">
      <Navbar />
    </div>
    <div className='unsubscribeContainer'>
   
    
    <h1 className='unsubscribeheading'>Sorry to see you go!<br></br> We've received your request to unsubscribe from our email list. We want to thank you for being a part of our community and for your support. We're sorry to see you leave, but we respect your decision. We'll be sure to update our records accordingly. If you ever change your mind and want to rejoin our list, you're always welcome to do so. <br></br>Thanks again and all the best!</h1>
 
    </div>
    <Footer/>
    </>
  )
}

export default Unsubscribe