import React from 'react'
import './PrivacyPolicy.css'
import Navbar from '../components/Navbar'
const Privacypolicy = () => {
  return (
    <div className='privacyPolicyPageContainer'>
 
    <div className="navbarContainer">
      <Navbar />
    </div>
    <h1 className='privacyPolicy'>Privacy Policy</h1>
    <h3 className='privacyPolciySubHeadingFirst'>Information Collected</h3>
    <p className='privacyPolciySubHeadingFirstInfo'>LMSRCM collects two types of user information:
<br></br><br></br>
    Data collected through optional, voluntary submissions. These include, but are not limited to, requesting information, applying for membership, purchasing products or services, participating in surveys, submitting questions or comments, and participating in polls.
    <br></br><br></br>
    Data gathered through aggregated visitor tracking, primarily by tallying page views across its websites. This data enables them to better tailor their services to their members and prospective members.</p>
    
    <h3 className='privacyPolciySubHeadingFirst'>Information Used</h3>
    <p className='privacyPolciySubHeadingFirstInfo'>The contact information you provide LMSRCM is used to help create and continually improve its services for you. It processes payments using payment information and takes precautions to keep that information secure (noted in the security section below). In the normal course of providing service, it creates and maintains other information such as customer or member account status, service selection, and customer or member logs. Your information is used by LMSRCM to make your experience with its services more personalized, convenient, and relevant to you.<br></br><br></br>
    LMSRCM uses your information to communicate with you about the status of your account, billing issues, and service updates via letter, email, or phone. It will also send you company and industry newsletters, product updates, and promotions from its select partners on a regular basis because we believe this information will be useful to you. You may opt-out or unsubscribe from receiving this type of correspondence by contacting us at  support@lmsrcm.com. Customers may not opt out of receiving information from LMSRCM that is required for the maintenance or updating of their account or system information.
    </p>
    <h3 className='privacyPolciySubHeadingFirst'>Information Security</h3>
    <p className='privacyPolciySubHeadingFirstInfo'>LMSRCM has implemented security measures to prevent unauthorized access to or alteration, disclosure, or destruction of personal information. It limits access to member information by LMSRCM's business partners who may require that information to operate, develop, or improve its services. If these parties fail to meet their confidentiality obligations, they may face disciplinary action, including termination and criminal prosecution.
    <br></br><br></br>LMSRCM maintains the confidentiality of your personal information. Its employees and other authorized personnel are required to keep your personal information confidential. It restricts access to personal information about you to only those individuals who need the information for a business reason, such as to provide services to you. It also keeps physical, electronic, and procedural safeguards in place to protect such data. Employees and other authorized personnel must follow all established policies, including this Privacy Policy.</p>
    <h3 className='privacyPolciySubHeadingFirst'>Information Sharing</h3>
    <p className='privacyPolciySubHeadingFirstInfo'>LMSRCM may share some information with select partners who provide services that its members and prospective members may be interested in. The information is shared infrequently and is only available with a non-disclosure agreement and a single use restriction. These third parties are not permitted to use your information for any purpose other than informing you of the applicable service.<br></br><br></br>LMSRCM will not otherwise disclose personal or account information unless there is reason to believe that doing so is necessary to identify, contact, or bring legal action against someone who may be causing harm or interfering with LMSRCM's, its customers', or others' rights or property. It may disclose information to regulatory authorities and law enforcement officials in accordance with applicable laws or when LMSRCM has a good faith belief that such disclosure is required by law.<br></br><br></br>LMSRCM will also not disclose to third parties the contents of any electronic mail or other electronic communications that LMSRCM stores or transmits for its customers unless: (a) it is a matter of public safety or policy; (b) it is in connection with the transfer of other business assets; (c) it is required to cooperate with orders, warrants, or other legal process that LMSRCM determines to be valid and enforceable in its sole discretion; and (d) it is necessary to provide services.<br></br><br></br>If LMSRCM sells, assigns, or transfers the portion of its business that provides you with a specific service, your personally identifiable information may be sold, assigned, or transferred as part of that transaction.</p>

    <h3 className='privacyPolciySubHeadingFirst'>Your Consent</h3>
    <p className='privacyPolciySubHeadingFirstInfo'>By using this site, becoming an LMSRCM member, purchasing products or services from them, or otherwise interacting with LMSRCM, you consent to the collection and use of information as described in this privacy statement. If LMSRCM changes its privacy policies, the changes will be posted on this page so that you are always aware of what information LMSRCM collects, how it uses the information, and under what circumstances LMSRCM discloses it.</p>
    
    <h3 className='privacyPolciySubHeadingFirst'>8,The Green Suite<br></br>
    #8268 Dover<br></br>
    DE,19901</h3>
    </div>
    
  )
}

export default Privacypolicy