
import { initializeApp } from "firebase/app";
// import {fs} from 'firebase/database/dist/firestore'
import {getFirestore} from 'firebase/firestore'
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDrgXfIn54PDRWrzJnu-JnYmbOnuS9nmno",
  authDomain: "lmsrcm-438b0.firebaseapp.com",
  projectId: "lmsrcm-438b0",
  storageBucket: "lmsrcm-438b0.appspot.com",
  messagingSenderId: "734324968983",
  appId: "1:734324968983:web:286242ebccd3b1382d1652",
  measurementId: "G-X1KJ8SK5C0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const fs = getFirestore(app);
export {fs}