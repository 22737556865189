import React from "react";
import { Navigation, Keyboard, Parallax, EffectFade, Autoplay, Pagination } from "swiper/modules";
import "swiper/css";

import Navbar from "./Navbar";
import "./Screenshots.css";

import { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
const Screenshots = () => {
  return (
    <div className="allScreenshotsContainer">
      <Swiper
    
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
       
        className="mySwiperr"
      >
        <SwiperSlide>
          {" "}
          <div className="leftAdminPanelSec">
          <div className="leftAdminPanelImgContainer">
          <img
          className="bottomAdminPanelImg"
          src='https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/images%2FleftAdminpanelImg.png?alt=media&token=397a32df-5645-4659-b79e-6faad3024e9c'
          alt="dashboard "
        />
        </div>

          </div>
          <div className="rightAdminPanelSec">
            <div className="rightAdminPanelHeading">
              Empower Your Teams with Seamless Learning and Development
            </div>
            <div className="rightAdminPanelTagline">
              -With a single click, you can create and manage your departments.<br></br>-Split your users to departments or responsibilities they cater to<br></br>-Get a clearer picture of the user's educational and resource needs.<br></br>-Assign trainings and
              optimize performance of your teams
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          {" "}
          <div className="leftAdminPanelSec">
          <div className="leftAdminPanelImgContainer">
          <img
          className="bottomAdminPanelImg"
          src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/images%2FLeftAdminPanelUserHome.png?alt=media&token=7d691850-232d-455e-a7fb-252e648d1117"
          alt="dashboard img"
        />
        </div>
          </div>
          <div className="rightAdminPanelSec">
            <div className="rightAdminPanelHeading">
            A one stop solution for all your trainings and CEUs all together
            </div>
            <div className="rightAdminPanelTagline">
           -Get an overview of the session and access it from your phone, tablet, or PC.<br></br>-Get a complete list of attended, pending, and completed sessions all in one place.<br></br>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          {" "}
          <div className="leftAdminPanelSec">
          <div className="leftAdminPanelImgContainer">
          <img
          className="bottomAdminPanelImg"
          src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/images%2FleftAdminpanelUserCEU.png?alt=media&token=8925c1eb-57ce-4b6d-9293-30b28cf9b4ec"
          alt="dashboard img"
        />
        </div>
          </div>
          <div className="rightAdminPanelSec">
            <div className="rightAdminPanelHeading">
            Maintain a certificate library and due date tracker for your Continuing Education Units.
            </div>
            <div className="rightAdminPanelTagline">
              -keeps you informed of which CEUs are valid until what date<br></br>-Complete the assigned trainings and instantly download the CEU in pdf format.<br></br>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Screenshots;


