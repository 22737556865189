import React, { useEffect,useState,useRef } from "react";
import { createPortal } from "react-dom";
import "./SpeakerModal.css";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { collection, addDoc } from "firebase/firestore";
import { fs } from "../firebase";
import emailjs from "@emailjs/browser";
import { InputText } from "primereact/inputtext";

const SpeakerModal = ({ closeSusanModal }) => {
  useEffect(() => {
    document.body.style.overflowY = "hidden";

    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [emailTop, setEmailTop] = useState("");
    const [successMsg, setSuccessmsg] = useState("");
    const [error, setError] = useState("");
    const [to_name, setto_name] = useState("")
    const [lastName, setlastName] = useState("")
    const [to_email, setto_email] = useState("")
    const [companyName, setComapnyName] = useState("")
    const [jobTitle, setjobTitle] = useState("")
    const [addToEmailList, setAddToEmailList] = useState(false);


    const form = useRef();
    const notify = () => {
      toast.success("You are successfully added to our emailing list!", {
        position: toast.POSITION.TOP_CENTER,
      });
    };
    const addDeatailsToEmailList = async (data) => {
      const { to_name, lastName, to_email, companyName, jobTitle } = data;
      // console.log(fname,lname,email,certification,phone,time,date,topicOfInterest)

      notify();

      const docRef = await addDoc(collection(fs, "NotifyMeEmailList"), {
        to_name,lastName,to_email,companyName,jobTitle,    addToEmailList,
      })
        .then(() => {
          setSuccessmsg("Your are successfully added to our emailing list!");
          setEmailTop("");
          setError("");
          setto_name('')
          setlastName('')
          setto_email('')
          setComapnyName('')
          setjobTitle('')
          setAddToEmailList(false);
          setTimeout(() => {
            setSuccessmsg("");
          }, 3000);
        })
        .catch((error) => setError(error.message));
  
    };
    const sendEmail = (formData) => {
        emailjs
          .sendForm(
            "service_s5e9vpe",
            "template_23e76xr",
            form.current,
            "Xt5MhTFtzdO3DyOFK"
          )
          .then(
            (result) => {
              console.log(result.text, "Email sent successfully!");
            },
            (error) => {
              console.log(error.text);
            }
          );
      };
  
      // Define a state variable to track whether the captcha is checked
  const [captchaChecked, setCaptchaChecked] = useState(false);
  
  // ...
  
  const onChange = (value) => {
    // Assuming that `value` is the value returned by ReCAPTCHA
    if (value) {
      // If the captcha is checked, clear the error message
      setCaptchaChecked(true);
    }
  };
  return createPortal(
    <>
      <div className="modalWrapper" onClick={closeSusanModal}></div>
      <div className="SpeakerModalContainerSusanModal">
      <div className="secondFoldNewSDOHPageRightRegColumn">
      <p className="secondFoldNewSDOHPageRightRegistrationHeading">
      Please fill this form to Register
      </p>
      <div className="emailInputANdlineDivSDOH">
        <form ref={form} className="classForm" onSubmit={handleSubmit(
          (data) => {
            
            sendEmail(data)
            addDeatailsToEmailList(data);
            setto_name('');
            setto_email('');
            setlastName('');
            setComapnyName('');
            setjobTitle('');
         
            })
        
        } autoComplete="off">
          <InputText
            type="text"
            className="p-inputtext-lg"
            placeholder="First Name"
            style={{ margin: "8px", width: "80%" }}
            name="to_name"
     
            {...register("to_name", { required: true, })}
          />
          {errors.to_name && <p style={{color:"red",marginLeft:"20px"}}>*First name is required.</p>}
          <InputText
            type="text"
            className="p-inputtext-lg"
            placeholder="Last Name"
            style={{ margin: "8px", width: "80%" }}
         
            {...register("lastName", { required: true, })}
          />
          {errors.lastName && <p style={{color:"red",marginLeft:"20px"}}>*Last name is required.</p>}
          <InputText
            type="email"
            className="p-inputtext-lg"
            placeholder="Email"
     
            style={{ margin: "8px", width: "80%" }}
            name="to_email"
            {...register("to_email", { required: true, pattern:{
              value:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              
            } })}
          />
          {errors.to_email && <p style={{color:"red",marginLeft:"20px"}}>*Please enter a valid email</p>}

          <InputText
            type="text"
            className="p-inputtext-lg"
            placeholder="Company Name"
          
            style={{ margin: "8px", width: "80%" }}
            {...register("companyName", { required: true })}
          />
          {errors.companyName && <p style={{color:"red",marginLeft:"20px"}}>*Company Name is required.</p>}

          <InputText
            type="text"
            className="p-inputtext-lg"
            placeholder="Job Title"
           
            style={{ margin: "8px", width: "80%" }}
            {...register("jobTitle", { required: true })}
          />
          {errors.jobTitle && <p style={{color:"red",marginLeft:"20px"}}>*Job Title is required.</p>}

          <ReCAPTCHA
            sitekey="6LdE0wYoAAAAAEBNcoxtn1YCs9D8MUJIEdDz5GuJ"
            onChange={onChange}
         style={{marginTop:"4px",marginBottom:"4px"}}
            />
   

          <div className="checkBoxDiv">
            <input type="checkbox" style={{ margin: "5px" }}
             checked={addToEmailList}
             
            onChange={() => setAddToEmailList(!addToEmailList)} />
            <p style={{ margin: "5px", fontSize: "12px" }}>
              Add me to your mailing list for updates, offers and
              rewards
            </p>
          </div>
          <button
            className="fifthFoldInfoContainerButton1"
            style={{ margin: "20px" }}
            value="Submit"
          >
            Submit
          </button>
          {/*<button className="firstFoldInfoContainerButton2">
Download Agenda
</button>*/}

          <p></p>
        </form>
      </div>
      <div></div>
    </div>
      </div>
    </>,
    document.querySelector(".MyModalDiv")
  );
};

export default SpeakerModal;
