import { Link } from "react-router-dom";
import "./SDOH.css";
import { InputText } from "primereact/inputtext";
import ReCAPTCHA from "react-google-recaptcha";
import Footer from "../components/Footer";
import { MdLiveTv } from "react-icons/md";
import { useState, useRef } from "react";
import Navbar from "../components/Navbar2";
import { BiVideoRecording } from "react-icons/bi";
import { IoPricetagsOutline } from "react-icons/io";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import "./Webinar.css";
import { BsFillCalendar2CheckFill,BsClock ,BsCalendarWeek} from "react-icons/bs";
import { BiDollar } from "react-icons/bi";
import Navbar2 from "../components/Navbar2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FcQuestions,
  FcVoicePresentation,
  FcKey,
  FcReading,
  FcRefresh,
  FcTodoList,
  FcFinePrint,
  FcCamcorderPro,
} from "react-icons/fc";
import { SiStarbucks } from "react-icons/si";

import { BsBookmarkHeart } from "react-icons/bs";
import { MdOutlineLiveTv, MdOutlineTimelapse } from "react-icons/md";
import { FiUserCheck } from "react-icons/fi";
import { BsPatchQuestion } from "react-icons/bs";
import { RxPencil2 } from "react-icons/rx";
import { GiArchiveRegister } from "react-icons/gi";
import { GiCaduceus } from "react-icons/gi";
import { BsQuestionSquare } from "react-icons/bs";
import ScrollAnimation from "react-animate-on-scroll";
import { AiOutlineDollar } from "react-icons/ai";
import Agenda from "../components/Agenda";
import SpeakerModal from "../components/SpeakerModal";
import JoinModal from "../components/JoinModal";
import { collection, addDoc } from "firebase/firestore";
import { fs } from "../firebase";
import NotifyMeModal from '../components/ICD10RegisterModal'
import FreeICD10RegisterModal from '../components/FreeICD10RegisterModal'

import emailjs from "@emailjs/browser";
const ICDTEN =
  () => {
    const [susanModal, setSusanModal] = useState(false)
    const closeSusanModal =()=>{
      setSusanModal(false);
    }
    const [icdFreeModal, setIcdFreeModal] = useState(false)
    const closeIcdFreeModal =()=>{
      setIcdFreeModal(false);
    }
    const [clickMenu, setClickMenu] = useState(false);
    const [inputMailList, setInputMailList] = useState("");
    const [inputJoinList, setInputJoinList] = useState("");
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [showAgenda, setShowAgenda] = useState(false);
    const [showSpeakerModal, setShowSpeakerModal] = useState(false);
    const [showJoinModal, setShowJoinModal] = useState(false);
    const [emailTop, setEmailTop] = useState("");
    const [successMsg, setSuccessmsg] = useState("");
    const [error, setError] = useState("");
    const [to_name, setto_name] = useState("")
    const [lastName, setlastName] = useState("")
    const [to_email, setto_email] = useState("")
    const [companyName, setComapnyName] = useState("")
    const [jobTitle, setjobTitle] = useState("")
    const [addToEmailList, setAddToEmailList] = useState(false);


    const form = useRef();
    const notify = () => {
      toast.success("You are successfully added to our emailing list!", {
        position: toast.POSITION.TOP_CENTER,
      });
    };
    const addDeatailsToEmailList = async (data) => {
      const { to_name, lastName, to_email, companyName, jobTitle } = data;
      // console.log(fname,lname,email,certification,phone,time,date,topicOfInterest)

      notify();

      const docRef = await addDoc(collection(fs, "ICD10RegistriesEmailList"), {
        to_name,lastName,to_email,companyName,jobTitle,    addToEmailList,
      })
        .then(() => {
          setSuccessmsg("Your are successfully added to our emailing list!");
          setEmailTop("");
          setError("");
          setto_name('')
          setlastName('')
          setto_email('')
          setComapnyName('')
          setjobTitle('')
          setAddToEmailList(false);
          setTimeout(() => {
            setSuccessmsg("");
          }, 3000);
        })
        .catch((error) => setError(error.message));
  
    };
    const handleImageChange = (index) => {
      setSelectedImageIndex(index);
    };

    const closeModal = () => {
      setShowAgenda(false);
    };
    const closeJoinModal = () => {
      setShowJoinModal(false);
      setInputJoinList("");
    };
    const closeSpeakerModal = () => {
      setShowSpeakerModal(false);
    };


    
    const sendEmail = (formData) => {
      emailjs
        .sendForm(
          "service_s5e9vpe",
          "template_86gkunk",
          form.current,
          "Xt5MhTFtzdO3DyOFK"
        )
        .then(
          (result) => {
            console.log(result.text, "Email sent successfully!");
          },
          (error) => {
            console.log(error.text);
          }
        );
    };

    // Define a state variable to track whether the captcha is checked
const [captchaChecked, setCaptchaChecked] = useState(false);

// ...

const onChange = (value) => {
  // Assuming that `value` is the value returned by ReCAPTCHA
  if (value) {
    // If the captcha is checked, clear the error message
    setCaptchaChecked(true);
  }
};

    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();


    return (
      <>
      {susanModal && <NotifyMeModal closeSusanModal={closeSusanModal} />}
      {icdFreeModal && <FreeICD10RegisterModal closeIcdFreeModal={closeIcdFreeModal} />}

      <div className="offerAboveNavbar">
      <p className="offerAboveNavbarP"> Free Starbucks Coffee Voucher for all attendees</p>
      </div>
        <Navbar />
        {/* <div className="blogPageContainer">
          <div className="horizontalLine"></div>
          <h2 className="firstFoldContainerh2">
            Social Determinants Of Health: A Coding And Billing Guide
          </h2>

          <div className="horizontalLine"></div>
    </div>*/}
    <div className="blogPageContainer">
      
    <h2 className="firstFoldContainerh2">
     2024 ICD-10 CM Changes And Updates
    </h2>

<div className="horizontalLine"></div> 
</div>
<div className="newFirstFoldContainerWebinar">
<div className="newFirstFoldContainerWebinarLeftContainer">
<div className="newFirstFoldContainerWebinarLeft">
<div className="DiveAboveSessionHighlights">
<p className="DiveAboveSessionHighlightsP">
  <h1 className="thirdFoldWebinarContainerCenterdivAbovePP">
  Webinar: 2024 ICD-10 CM Changes and Updates
  </h1>
 
  CDC unveils ICD-10-CM Codes for the upcoming FY 2024 with 395 New Additions, deletion of 25 existing codes and revises 22 codes. Majority of the new codes were proposed for greater specificity and to increase tracking and progress. The updates go into effect from October 1, 2023, starting from visits or discharges occurring on or after October 1 , 2023.

</p>
</div>
</div>
<div className="newFirstFoldContainerWebinarLeftdetails">
<h2 className="newFirstFoldContainerWebinarLeftdetailsH2">28th September,2023</h2>
<div className="newAapcImageConatiner">
<img src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/SDOH%2Faapc-ceu-approved-200x74%20(1).gif?alt=media&token=75a7ea03-bccf-4385-bb16-6d61b8e68f41" alt="AAPCE CEU Approved" className="newAapcImage"/>
<div className="WebinarInfoCube2">

<img
  className="starbucksCoffeeImageSDOH"
src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/SDOH%2FThanksCaffeineBoost.png?alt=media&token=26ff7827-1384-4370-8c84-509d7576f0b2"
/>
<h1 className="Free">Free</h1>

</div>

</div>

</div>
</div>
<div className="newFirstFoldContainerWebinarRight">
<div className="newFirstFoldContainerWebinarRightSpeakerImageContainer">
<img src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/ICD%2010%2FSiji_photo-removebg-preview.png?alt=media&token=47d2725d-d8c4-4acf-a0f1-e054623dab78" alt="Siji Susan Joy" className="newFirstFoldContainerWebinarRightSpeakerImage"/>
</div>
<div className="newFirstFoldContainerWebinarRightSpeakerDeatils">
<h1 className="newFirstFoldContainerWebinarRightSpeakerDeatilsH1">Siji Susan Joy<br></br>CCS,CPC</h1>
</div>
</div>
</div>
    <div className="WebinarInfoCubeDetailsContainer">
    <div className="WebinarInfoCubeDetails">
      <div className="WebinarInfoCube">
      <div className="WebinarInfoCubeIconDiv">
        <MdLiveTv style={{ fontSize: "48px",color:"white" }} />
      </div>
        <p className="WebinarInfoCubeP">Live</p>
      </div>
      <div className="WebinarInfoCube">
      <div className="WebinarInfoCubeIconDiv">
        <BiVideoRecording style={{ fontSize: "48px",color:"white" }} />
      </div>
        <p className="WebinarInfoCubeP2">12 Months Recording</p>
      </div>
     
      <div className="WebinarInfoCube">
    
      <div className="WebinarInfoCubeIconDiv">
      <BsClock style={{ fontSize: "42px",color:"white", marginTop:"10px" }} />
    
      </div>
        <p className="WebinarInfoCubeP">Duration</p>
        <p className="WebinarInfoCubeP2">1 hour</p>
      </div>
      <div className="WebinarInfoCube">
      <div className="WebinarInfoCubeIconDiv">
      <BsCalendarWeek style={{ fontSize: "48px",color:"white" }} />
    
      </div>
        <p className="WebinarInfoCubeP2">28th September<br></br>2023</p>
      </div>
    
    </div>
  
  </div>
     <div className="WebinarInfoCubeDetailsContainerResponsive">
              <div className="WebinarInfoCubeDetails">
              <div className="WebinarInfoCubeDetails1">
                <div className="WebinarInfoCube">
                <div className="WebinarInfoCubeIconDiv">
                  <MdLiveTv style={{ fontSize: "48px",color:"white" }} />
                </div>
                  <p className="WebinarInfoCubeP">Live</p>
                </div>
                <div className="WebinarInfoCube">
                <div className="WebinarInfoCubeIconDiv">
                  <BiVideoRecording style={{ fontSize: "48px",color:"white" }} />
                </div>
                  <p className="WebinarInfoCubeP2">12 Months Recording</p>
                </div>
                </div>
             
                  <div className="WebinarInfoCubeDetails1">
                <div className="WebinarInfoCube">
              
                <div className="WebinarInfoCubeIconDiv">
                <BsClock style={{ fontSize: "42px",color:"white", marginTop:"10px" }} />
              
                </div>
                  <p className="WebinarInfoCubeP">Duration</p>
                  <p className="WebinarInfoCubeP2">1 hour</p>
                </div>
                <div className="WebinarInfoCube">
                <div className="WebinarInfoCubeIconDiv">
                <BsCalendarWeek style={{ fontSize: "48px",color:"white" }} />
              
                </div>
                  <p className="WebinarInfoCubeP2">28th September<br></br>2023</p>
                </div>
                </div>
              </div>
       
            </div>
 
            <div className="newSecondFoldWebinarContainer">
            
            <div className="newSecondFoldWebinarContainerLeft">
            
            <div className="sessionHighlights">
            <div className="sessionHighlightsDivHeading">
              <h1 className="thirdFoldWebinarContainerCenterdivAboveP">
                Session Highlights
              </h1>
            </div>
            <div className="sessionHighlightsInfo">
            {/*  <div className="left">
                    <img
                      src={imageUrls[selectedImageIndex]}
                      alt="Session Highlight"
                      className="highlightImage"
                    />
</div>*/}
            <div className="right">
              <ul className="rowICD">
              <li style={{ paddingTop: "5px", paddingBottom: "5px" }}>
              Notable proposed ICD-10-CM code additions related to
              <br />
              - Parkinson’s disease <br />
              - Sickle Cell Anemia <br />
              - Disease of the Eye and Adnexa
              <br />
              - Osteoporosis
              <br />
              - Mammographic findings
              <br />
              - Insulin resistance
              <br />- External cause codes that specify foreign body
              entering through a natural orifice, to - capture accidents and
              injuries
            </li>

               
            <li>
            New codes added and revisions in the congenital malformations,
            deformations and chromosomal abnormalities (Q00-Q99) chapter
          </li>
          <li>
          Guidelines update : coding of pre-op Covid-19 screening,
          comas, Acute Myocardial Infarction (AMI)
        </li>
        <li>Changes in the tabular addenda</li>
               
                  
              </ul>
            </div>
          </div>
          </div>
            </div>
            <div className="newSecondFoldWebinarContainerRight">
            <div className="newSecondFoldWebinarContainerRightJoinNowContainer">
            <div className="newSecondFoldWebinarContainerRightJoinNowContainerLeft">
            <h3 className="secondFoldNewSDOHPageRightCoffeeContainerHeading">Live<br></br>12 Months Recording<br></br>Free Starbucks Coffee </h3>
            <h1 className="secondFoldNewSDOHPageRightCoffeeContainerPrice">$99</h1>
            <button
            className="fifthFoldInfoContainerButton1"
            style={{ margin: "20px" }}
            onClick={() => setSusanModal(true)}
          >
           Book Now
          </button>
          </div>
          <div className="newSecondFoldWebinarContainerRightJoinNowContainerRight">
          <h3 className="secondFoldNewSDOHPageRightCoffeeContainerHeading">Live
          <br></br> No 12 Months Recording<br></br>No Free Starbucks Coffee 
          
          </h3>
          <h1 className="secondFoldNewSDOHPageRightCoffeeContainerPrice2">Free</h1>

          <button
          className="fifthFoldInfoContainerButton11"
          style={{ margin: "20px" }}
          onClick={() => setIcdFreeModal(true)}

        >
         Register Now
        </button>
        </div>
            </div>
            </div>

            </div>

              <div className="FourthFoldWebinarContainer">
              <div className="FourthFoldWebinarContainerHeadingDiv">
                <h1 className="FourthFoldWebinarContainerHeading">
                  <p className="FourthFoldWebinarContainerHeadingFAQ"> </p>
                  <ScrollAnimation
                    duration={5}
                    animateIn="hinge"
                    initiallyVisible={true}
                  >
                    <p className="FourthFoldWebinarContainerHeadingFAQ">
                      Frequently Asked Questions
                    </p>
                  </ScrollAnimation>
                  <span className="FourthFoldWebinarContainerHeadingTagline">
                    Quick answers to questions you may have.Cant't find what you are
                    looking for?{" "}
                    <a className="FourthFoldWebinarContainerHeadingTaglineLink">
                      Get in touch.
                    </a>
                  </span>
                </h1>
              </div>
              <div className="faqDIV">
                <div className="faqDIVLeft">
                  <div className=" faqDIVLeftRow">
                    <div className="faqDIVLeftRowIconDiv">
                      <MdOutlineLiveTv className="faqDIVLeftRowIcon" />
                    </div>
                    <div className="faqDIVLeftRowInfoDiv">
                      <div className="faqDIVLeftRowInfoHeadingDiv">
                        <h1 className="faqDIVLeftRowInfoHeading">
                          {" "}
                          What if I can not attend live?
                        </h1>
                      </div>
                      <div className="faqDIVLeftRowInfoDivInfoDiv">
                        <p className="faqDIVLeftRowInfoDivInfoP">
                          You’ll have access to recording(On demand) and transcript
                          for 12 months. You can still ask questions to the Speaker
                          via email.
                        </p>
                      </div>
                    </div>
                  </div>
      
                  <div className=" faqDIVLeftRow">
                    <div className="faqDIVLeftRowIconDiv">
                      <MdOutlineTimelapse className="faqDIVLeftRowIcon" />
                    </div>
                    <div className="faqDIVLeftRowInfoDiv">
                      <div className="faqDIVLeftRowInfoHeadingDiv">
                        <h1 className="faqDIVLeftRowInfoHeading">
                          {" "}
                          How long is the webinar?
                        </h1>
                      </div>
                      <div className="faqDIVLeftRowInfoDivInfoDiv">
                        <p className="faqDIVLeftRowInfoDivInfoP">
                          {" "}
                          The webinar is about 60 minutes long, including Q&A
                          sessions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" faqDIVLeftRow">
                    <div className="faqDIVLeftRowIconDiv">
                      <AiOutlineDollar className="faqDIVLeftRowIcon" />
                    </div>
                    <div className="faqDIVLeftRowInfoDiv">
                      <div className="faqDIVLeftRowInfoHeadingDiv">
                        <h1 className="faqDIVLeftRowInfoHeading">
                          How much does the webinar cost?
                        </h1>
                      </div>
                      <div className="faqDIVLeftRowInfoDivInfoDiv">
                        <p className="faqDIVLeftRowInfoDivInfoP">
                          {" "}
                          The registration fee for the webinar is $99.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" faqDIVLeftRow">
                    <div className="faqDIVLeftRowIconDiv">
                      <RxPencil2 className="faqDIVLeftRowIcon" />
                    </div>
                    <div className="faqDIVLeftRowInfoDiv">
                      <div className="faqDIVLeftRowInfoHeadingDiv">
                        <h1 className="faqDIVLeftRowInfoHeading">
                          {" "}
                          How do I register for the webinar?
                        </h1>
                      </div>
                      <div className="faqDIVLeftRowInfoDivInfoDiv">
                        <p className="faqDIVLeftRowInfoDivInfoP">
                          {" "}
                          To register for the webinar, click on the button below. You
                          will receive a confirmation email with the webinar link and
                          instructions a couple days before the live webinar.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" faqDIVLeftRow">
                    <div className="faqDIVLeftRowIconDiv">
                      <GiCaduceus className="faqDIVLeftRowIcon" />
                    </div>
                    <div className="faqDIVLeftRowInfoDiv">
                      <div className="faqDIVLeftRowInfoHeadingDiv">
                        <h1 className="faqDIVLeftRowInfoHeading">
                          {" "}
                          How do I earn CEU credit from this webinar?
                        </h1>
                      </div>
                      <div className="faqDIVLeftRowInfoDivInfoDiv">
                        <p className="faqDIVLeftRowInfoDivInfoP">
                          {" "}
                          To earn CEU credit from this webinar, you need to attend the
                          live session or watch the recording. You will also need to
                          complete a quiz. You will receive a certificate of
                          completion with 1 CEU credit from AAPC for this Webinar.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="faqDIVRight"></div>
              </div>
              <p className="Astrix">*The merchants represented are not sponsors of the rewards or otherwise affiliated with this company. The logos and other identifying marks attached are trademarks of and owned by each represented company and/or its affiliates.


              The Starbucks word mark and the Starbucks Logo are trademarks of Starbucks Corporation. Starbucks is also the owner of the Copyrights in the Starbucks Logo and the Starbucks Card designs. All rights reserved. Starbucks is not a participating partner or sponsor in this offer.
              
              Please visit each company's website for additional terms and conditions.</p>
           
              </div>
      
        <div className="footerSDOH">
          <Footer />
        </div>
      </>
    );
  };

export default ICDTEN;
