import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import "./SpeakerModal.css";
const SpeakerModal = ({ closeSusanModal }) => {
  useEffect(() => {
    document.body.style.overflowY = "hidden";

    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, []);

  return createPortal(
    <>
      <div className="modalWrapper" onClick={closeSusanModal}></div>
      <div className="SpeakerModalContainerSusanModal">
     <div className="ModalSpeakerImgAndName">
     <div className="ModalSpeakerNameAndCertification">
    <h1 className="ModalSpeakerNameAndCertificationName">Siji Susan Joy </h1>
    <h3 className="ModalSpeakerNameAndCertificationCertification">MS,BPT,SMC,CPC,CCS,AHIMA Apprvd Trainer</h3>
     </div>
     <div className="ModalSpeakerNameAndCertificationSpeakerImage">
  
     <img src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/ICD%2010%2FuserImg.png?alt=media&token=fcf10fd0-ceea-4959-92d3-f27b6740c043" className="ModalSpeakerNameAndCertificationSpeakerImageImg" alt=" speaker image"/>
     </div>
     </div>
     <div className="ModalSpeakerInfo">
     <p className="ModalSpeakerInfoP">
     Over 18 years of experience in HIM domain with extensive program/solution development. Has been a technology consultant for analytics-driven organizations, providing solution/product development strategies to identify opportunities, assess available data, define solutions, and improve algorithms to capture real value to organizations in strengthening innovation and support health systems provide improved patient care. 
     <br />
<br />

Organizational leadership experience in Healthcare RCM analytics and clinical coding, delivering projects for providers & payers of US, UAE, & Australian industries. 
<br />
<br />

Expertise is in Business Strategy & Product Planning using agile Scrum and Jira, Execution of healthcare data and data analytics, Client-centric operational skill to build relationships towards successful project launches, retention, and repeat customers, Revenue cycle solutions, Empowerment of HIM coding unit start-ups
<br />
<br />

Business Intelligence contributions towards Patient Care Platforms, Payment Integrity Audit Solution, EHR development, and Product development strategies that combine predictive analytics 
<br />
<br />

- Complex Care Management Model that supports severity adjusted care planning, Early detection, prevention and intervention, Episodic care, and management of unstable patients living in the community
<br />
<br />

-Population Health Analytics that focus on health status, health improvement and chronic condition management 
<br />
<br />

- Evidence-based quality measure development and Healthcare Claims Fraud and Abuse detection
Achievements
<br />
<br />

- Contribution to developing a price transparency tool for HealthyPrice, US, used widely by employees and employers, providers for price transparency 
<br />
<br />

- Successful implementation for leading specialized health insurers, providing comprehensive health insurance solutions to more than 2.8 million members in UAE. The solution generates output with less than 10% false hits on pre-post-payment audits and recovery Healthcare data analytics
<br />
<br />

- Implemented Population Health Monitoring towards identification of unwell patients and improved disease prevention and management, leading to financial savings
<br />
<br />

-Have led various project launches and has worked with the US team to ensure HIM coding processes are managed to International standards
<br />
<br />

- Responsibilities include requirement analysis & information gathering, business mapping & solutions definition; developing user stories using Jira. 
<br />
<br />

Additional Skill Acquisition Program (ASAP), Kerala Gov -Technical Committee Member of Clinical Coding
<br />
<br />

- Educator - ICD 10 CM/PCS educator & Medical chart analysis for deficiency management.

     </p>
     </div>
      </div>
    </>,
    document.querySelector(".MyModalDiv")
  );
};

export default SpeakerModal;
