import { Link } from "react-router-dom";
import "./SDOH.css";
import { InputText } from "primereact/inputtext";
import Footer from "../components/Footer";
import { MdLiveTv } from "react-icons/md";
import { useState, useRef } from "react";
import Navbar from "../components/Navbar2";
import { BiVideoRecording } from "react-icons/bi";
import { IoPricetagsOutline } from "react-icons/io";
import React, { useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import "./Webinar.css";
import Navbar2 from "../components/Navbar2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FcQuestions,
  FcVoicePresentation,
  FcKey,
  FcReading,
  FcRefresh,
  FcTodoList,
  FcFinePrint,
  FcCamcorderPro,
} from "react-icons/fc";
import { SiStarbucks } from "react-icons/si";

import { BsBookmarkHeart } from "react-icons/bs";
import { MdOutlineLiveTv, MdOutlineTimelapse } from "react-icons/md";
import { FiUserCheck } from "react-icons/fi";
import { BsPatchQuestion } from "react-icons/bs";
import { RxPencil2 } from "react-icons/rx";
import { GiArchiveRegister } from "react-icons/gi";
import { GiCaduceus } from "react-icons/gi";
import { BsQuestionSquare } from "react-icons/bs";
import ScrollAnimation from "react-animate-on-scroll";
import { AiOutlineDollar } from "react-icons/ai";
import Agenda from "../components/Agenda";
import SpeakerModal from "../components/SpeakerModal";
import JoinModal from "../components/JoinModal";
import { collection, addDoc } from "firebase/firestore";
import { fs } from "../firebase";
import NotifyMeModal from '../components/SDOHRegisterModal'
import emailjs from "@emailjs/browser";
import { BsFillCalendar2CheckFill,BsClock,BsCalendarWeek } from "react-icons/bs";
import { BiDollar } from "react-icons/bi";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
const ElevatingEducationThroughAdvancedPromptsAJourneyIntoCreativeLearning =
  () => {
    const [clickMenu, setClickMenu] = useState(false);
    const [inputMailList, setInputMailList] = useState("");
    const [inputJoinList, setInputJoinList] = useState("");
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [showAgenda, setShowAgenda] = useState(false);
    const [showSpeakerModal, setShowSpeakerModal] = useState(false);
    const [showJoinModal, setShowJoinModal] = useState(false);
    const [emailTop, setEmailTop] = useState("");
    const [successMsg, setSuccessmsg] = useState("");
    const [error, setError] = useState("");
    const [to_name, setto_name] = useState("")
    const [lastName, setlastName] = useState("")
    const [to_email, setto_email] = useState("")
    const [companyName, setComapnyName] = useState("")
    const [jobTitle, setjobTitle] = useState("")
    const [addToEmailList, setAddToEmailList] = useState(false);


    const form = useRef();
    const notify = () => {
      toast.success("You are successfully added to our emailing list!", {
        position: toast.POSITION.TOP_CENTER,
      });
    };
    const addDeatailsToEmailList = async (data) => {
      const { to_name, lastName, to_email, companyName, jobTitle } = data;
      // console.log(fname,lname,email,certification,phone,time,date,topicOfInterest)

      notify();

      const docRef = await addDoc(collection(fs, "SDOHRegistriesEmailList"), {
        to_name,lastName,to_email,companyName,jobTitle,    addToEmailList,
      })
        .then(() => {
          setSuccessmsg("Your are successfully added to our emailing list!");
          setEmailTop("");
          setError("");
          setto_name('')
          setlastName('')
          setto_email('')
          setComapnyName('')
          setjobTitle('')
          setAddToEmailList(false);
          setTimeout(() => {
            setSuccessmsg("");
          }, 3000);
        })
        .catch((error) => setError(error.message));
  
    };
    const handleImageChange = (index) => {
      setSelectedImageIndex(index);
    };

    const closeModal = () => {
      setShowAgenda(false);
    };
    const closeJoinModal = () => {
      setShowJoinModal(false);
      setInputJoinList("");
    };
    const closeSpeakerModal = () => {
      setShowSpeakerModal(false);
    };

    const [susanModal, setSusanModal] = useState(false)
    const closeSusanModal =()=>{
      setSusanModal(false);
    }
  
    
    const sendEmail = (formData) => {
      emailjs
        .sendForm(
          "service_s5e9vpe",
          "template_86gkunk",
          form.current,
          "Xt5MhTFtzdO3DyOFK"
        )
        .then(
          (result) => {
            console.log(result.text, "Email sent successfully!");
          },
          (error) => {
            console.log(error.text);
          }
        );
    };

    // Define a state variable to track whether the captcha is checked
const [captchaChecked, setCaptchaChecked] = useState(false);

// ...

const onChange = (value) => {
  // Assuming that `value` is the value returned by ReCAPTCHA
  if (value) {
    // If the captcha is checked, clear the error message
    setCaptchaChecked(true);
  }
};

    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();


    return (
      <>
      {susanModal && <NotifyMeModal closeSusanModal={closeSusanModal} />}
      <div className="offerAboveNavbar">
      <p className="offerAboveNavbarP"> Free “Free Starbucks Coffee* for all Registrants”</p>
      </div>
        <Navbar />
        <div className="blogPageContainer">
      
          <h2 className="firstFoldContainerh2">
            Social Determinants Of Health: A Coding And Billing Guide
          </h2>

      <div className="horizontalLine"></div> 
    </div>
    <div className="newFirstFoldContainerWebinar">
    <div className="newFirstFoldContainerWebinarLeftContainer">
    <div className="newFirstFoldContainerWebinarLeft">
    <div className="DiveAboveSessionHighlights">
    <p className="DiveAboveSessionHighlightsP">
      <h1 className="thirdFoldWebinarContainerCenterdivAbovePP">
        The SDOH Training
      </h1>
      Are you ready to master the art of SDOH
      coding?
     Coding for SDOH is essential for capturing
      the patient's health status, identifying gaps in care,
      informing interventions, measuring outcomes and
      supporting value-based payment models. But coding for
      SDOH can be tricky. You need to know where to find the
      relevant information in the medical record, how to apply
      the official coding guidelines and standards, and how to
      keep up with the changes and updates in SDOH coding.
       Join us on Social Determinants of Health: A
      Coding Guide.
    
    </p>
  </div>
    </div>
    <div className="newFirstFoldContainerWebinarLeftdetails">
    <h2 className="newFirstFoldContainerWebinarLeftdetailsH2">28th September,2023<br></br>3:00 PM ET</h2>

    <div className="newAapcImageConatiner">
    <img src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/SDOH%2Faapc-ceu-approved-200x74%20(1).gif?alt=media&token=75a7ea03-bccf-4385-bb16-6d61b8e68f41" alt="AAPCE CEU Approved" className="newAapcImage"/>
    <div className="WebinarInfoCube2">

    <img
      className="starbucksCoffeeImageSDOH"
    src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/SDOH%2FThanksCaffeineBoost.png?alt=media&token=26ff7827-1384-4370-8c84-509d7576f0b2"
  />
<h1 className="Free">Free</h1>

    </div>

    </div>

    </div>
    </div>
    <div className="newFirstFoldContainerWebinarRight">
    <div className="newFirstFoldContainerWebinarRightSpeakerImageContainer">
    <img src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/SDOH%2FCaitlinWhamSpeakerImage-removebg-preview.png?alt=media&token=80233301-c788-4797-86d1-4bc37e2160ac" alt="Caitlin Wham" className="newFirstFoldContainerWebinarRightSpeakerImage"/>
    </div>
    <div className="newFirstFoldContainerWebinarRightSpeakerDeatils">
    <h1 className="newFirstFoldContainerWebinarRightSpeakerDeatilsH1">Caitlin Wham<br></br>CCS</h1>
    </div>
    </div>
    </div>
    <div className="WebinarInfoCubeDetailsContainer">
                <div className="WebinarInfoCubeDetails">
                  <div className="WebinarInfoCube">
                  <div className="WebinarInfoCubeIconDiv">
                    <MdLiveTv style={{ fontSize: "48px",color:"white" }} />
                  </div>
                    <p className="WebinarInfoCubeP">Live</p>
                  </div>
                  <div className="WebinarInfoCube">
                  <div className="WebinarInfoCubeIconDiv">
                    <BiVideoRecording style={{ fontSize: "48px",color:"white" }} />
                  </div>
                    <p className="WebinarInfoCubeP2">12 Months Recording</p>
                  </div>
                
                  <div className="WebinarInfoCube">
                
                  <div className="WebinarInfoCubeIconDiv">
                  <BsClock style={{ fontSize: "42px",color:"white", marginTop:"10px" }} />
                
                  </div>
                    <p className="WebinarInfoCubeP">Duration</p>
                    <p className="WebinarInfoCubeP2">1 hour</p>
                  </div>
                  <div className="WebinarInfoCube">
                  <div className="WebinarInfoCubeIconDiv">
                  <BsCalendarWeek style={{ fontSize: "48px",color:"white" }} />
                
                  </div>
                    <p className="WebinarInfoCubeP2">28th September<br></br>2023</p>
                  </div>
                </div>
               
              </div>
              <div className="WebinarInfoCubeDetailsContainerResponsive">
              <div className="WebinarInfoCubeDetails">
              <div className="WebinarInfoCubeDetails1">
                <div className="WebinarInfoCube">
                <div className="WebinarInfoCubeIconDiv">
                  <MdLiveTv style={{ fontSize: "48px",color:"white" }} />
                </div>
                  <p className="WebinarInfoCubeP">Live</p>
                </div>
                <div className="WebinarInfoCube">
                <div className="WebinarInfoCubeIconDiv">
                  <BiVideoRecording style={{ fontSize: "48px",color:"white" }} />
                </div>
                  <p className="WebinarInfoCubeP2">12 Months Recording</p>
                </div>
                </div>
                <div className="WebinarInfoCubeDetails2">
                  <div className="WebinarInfoCube">
                  <img
                    style={{borderRadius:"10px"}}
                  src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/SDOH%2FThanksCaffeineBoost.png?alt=media&token=26ff7827-1384-4370-8c84-509d7576f0b2"
                />
                  </div>
                  </div>
                  <div className="WebinarInfoCubeDetails1">
                <div className="WebinarInfoCube">
              
                <div className="WebinarInfoCubeIconDiv">
                <BsClock style={{ fontSize: "42px",color:"white", marginTop:"10px" }} />
              
                </div>
                  <p className="WebinarInfoCubeP">Duration</p>
                  <p className="WebinarInfoCubeP2">1 hour</p>
                </div>
                <div className="WebinarInfoCube">
                <div className="WebinarInfoCubeIconDiv">
                <BsCalendarWeek style={{ fontSize: "48px",color:"white" }} />
              
                </div>
                  <p className="WebinarInfoCubeP2">28th September<br></br>2023</p>
                </div>
                </div>
              </div>
            
            </div>

            <div className="newSecondFoldWebinarContainer">
            <div className="newSecondFoldWebinarContainerLeft">
            <div className="sessionHighlights">
            <div className="sessionHighlightsDivHeading">
              <h1 className="thirdFoldWebinarContainerCenterdivAboveP">
                Session Highlights
              </h1>
            </div>
            <div className="sessionHighlightsInfo">
            {/*  <div className="left">
                    <img
                      src={imageUrls[selectedImageIndex]}
                      alt="Session Highlight"
                      className="highlightImage"
                    />
</div>*/}
            <div className="right">
              <ul className="rowICD">
                <li style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                What are SDOH and why they matter for health care
                quality and equity
                </li>

                <li>
                The coding professional’s role in SDOH data
                capture and reporting
                </li>
                <li>
                The 5 key areas of SDOH and their corresponding
                codes
                </li>
                <li>  The official coding guidance on SDOH from
                ICD-10-CM, Z codes and other sources</li>
                <li> The 2023 updates to SDOH coding and what they mean
                for your practice</li>
                  <li> Where to locate SDOH in the medical record and how
                  to code them accurately and consistently</li>
                  <li>Examples of SDOH coding scenarios and best
                  practices</li>
                  
              </ul>
            </div>
          </div>
          </div>
            </div>
            <div className="newSecondFoldWebinarContainerRight">
            <div className="newSecondFoldWebinarContainerRightJoinNowContainer">
        
            <h3 className="secondFoldNewSDOHPageRightCoffeeContainerHeading">Live<br></br>12 Months Recording<br></br>Free Starbucks Coffee Voucher </h3>
            <h1 className="secondFoldNewSDOHPageRightCoffeeContainerPrice">$99</h1>
            <button
            className="fifthFoldInfoContainerButton1"
            style={{ margin: "20px" }}
            onClick={() => setSusanModal(true)}
          >
           Register Now
          </button>
        
            </div>
            </div>

            </div>

            <div className="ThirdFoldContainer">
            <div className="ThirdFold">
              <div className="FourthFoldWebinarContainer">
                <div className="FourthFoldWebinarContainerHeadingDiv">
                  <h1 className="FourthFoldWebinarContainerHeading">
                    <p className="FourthFoldWebinarContainerHeadingFAQ">
                      {" "}
                    </p>
                    <ScrollAnimation
                      duration={5}
                      animateIn="hinge"
                      initiallyVisible={true}
                    >
                      <p className="FourthFoldWebinarContainerHeadingFAQ">
                        Frequently Asked Questions
                      </p>
                    </ScrollAnimation>
                    <span className="FourthFoldWebinarContainerHeadingTagline">
                      Quick answers to questions you may have.Cant't find
                      what you are looking for?{" "}
                      <a className="FourthFoldWebinarContainerHeadingTaglineLink">
                        Get in touch.
                      </a>
                    </span>
                  </h1>
                </div>
                <div className="faqDIV">
                  <div className="faqDIVLeft">
                    <div className=" faqDIVLeftRow">
                      <div className="faqDIVLeftRowIconDiv">
                        <MdOutlineLiveTv className="faqDIVLeftRowIcon" />
                      </div>
                      <div className="faqDIVLeftRowInfoDiv">
                        <div className="faqDIVLeftRowInfoHeadingDiv">
                          <h1 className="faqDIVLeftRowInfoHeading">
                            {" "}
                            What if I can not attend live?
                          </h1>
                        </div>
                        <div className="faqDIVLeftRowInfoDivInfoDiv">
                          <p className="faqDIVLeftRowInfoDivInfoP">
                            You’ll have access to recording(On demand) and
                            transcript for 12 months. You can still ask
                            questions to the Speaker via email.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className=" faqDIVLeftRow">
                      <div className="faqDIVLeftRowIconDiv">
                        <BsPatchQuestion className="faqDIVLeftRowIcon" />
                      </div>
                      <div className="faqDIVLeftRowInfoDiv">
                        <div className="faqDIVLeftRowInfoHeadingDiv">
                          <h1 className="faqDIVLeftRowInfoHeading">
                            What is the purpose of this webinar?
                          </h1>
                        </div>
                        <div className="faqDIVLeftRowInfoDivInfoDiv">
                          <p className="faqDIVLeftRowInfoDivInfoP">
                            {" "}
                            The purpose of this webinar is to teach you how
                            to code for social determinants of health (SDOH)
                            and improve the quality of care for your
                            patients.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className=" faqDIVLeftRow">
                      <div className="faqDIVLeftRowIconDiv">
                        <FiUserCheck className="faqDIVLeftRowIcon" />
                      </div>
                      <div className="faqDIVLeftRowInfoDiv">
                        <div className="faqDIVLeftRowInfoHeadingDiv">
                          <h1 className="faqDIVLeftRowInfoHeading">
                            Who is this webinar for?
                          </h1>
                        </div>
                        <div className="faqDIVLeftRowInfoDivInfoDiv">
                          <p className="faqDIVLeftRowInfoDivInfoP">
                            {" "}
                            This webinar is for coding professionals who
                            want to enhance their skills and knowledge in
                            SDOH coding. It is also suitable for anyone who
                            is interested in learning more about SDOH and
                            their impact on health care.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className=" faqDIVLeftRow">
                      <div className="faqDIVLeftRowIconDiv">
                        <GiArchiveRegister className="faqDIVLeftRowIcon" />
                      </div>
                      <div className="faqDIVLeftRowInfoDiv">
                        <div className="faqDIVLeftRowInfoHeadingDiv">
                          <h1 className="faqDIVLeftRowInfoHeading">
                            {" "}
                            What will I learn from this webinar?
                          </h1>
                        </div>
                        <div className="faqDIVLeftRowInfoDivInfoDiv">
                          <p className="faqDIVLeftRowInfoDivInfoP">
                            {" "}
                            In this webinar, you will learn:
                            <br></br>
                            - What are SDOH and why they matter for health
                            care quality and equity
                            <br />
                            - The coding professional’s role in SDOH data
                            capture and reporting
                            <br />
                            - The 5 key areas of SDOH and their
                            corresponding codes
                            <br />
                            - The official coding guidance on SDOH from
                            ICD-10-CM, Z codes and other sources
                            <br />
                            - The 2023 updates to SDOH coding and what they
                            mean for your practice
                            <br />
                            - Where to locate SDOH in the medical record and
                            how to code them accurately and consistently
                            <br />- Examples of SDOH coding scenarios and
                            best practices
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className=" faqDIVLeftRow">
                      <div className="faqDIVLeftRowIconDiv">
                        <MdOutlineTimelapse className="faqDIVLeftRowIcon" />
                      </div>
                      <div className="faqDIVLeftRowInfoDiv">
                        <div className="faqDIVLeftRowInfoHeadingDiv">
                          <h1 className="faqDIVLeftRowInfoHeading">
                            {" "}
                            How long is the webinar?
                          </h1>
                        </div>
                        <div className="faqDIVLeftRowInfoDivInfoDiv">
                          <p className="faqDIVLeftRowInfoDivInfoP">
                            {" "}
                            The webinar is about 60 minutes long, including
                            Q&A sessions.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className=" faqDIVLeftRow">
                      <div className="faqDIVLeftRowIconDiv">
                        <AiOutlineDollar className="faqDIVLeftRowIcon" />
                      </div>
                      <div className="faqDIVLeftRowInfoDiv">
                        <div className="faqDIVLeftRowInfoHeadingDiv">
                          <h1 className="faqDIVLeftRowInfoHeading">
                            How much does the webinar cost?
                          </h1>
                        </div>
                        <div className="faqDIVLeftRowInfoDivInfoDiv">
                          <p className="faqDIVLeftRowInfoDivInfoP">
                            {" "}
                            The registration fee for the webinar is $99.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className=" faqDIVLeftRow">
                      <div className="faqDIVLeftRowIconDiv">
                        <RxPencil2 className="faqDIVLeftRowIcon" />
                      </div>
                      <div className="faqDIVLeftRowInfoDiv">
                        <div className="faqDIVLeftRowInfoHeadingDiv">
                          <h1 className="faqDIVLeftRowInfoHeading">
                            {" "}
                            How do I register for the webinar?
                          </h1>
                        </div>
                        <div className="faqDIVLeftRowInfoDivInfoDiv">
                          <p className="faqDIVLeftRowInfoDivInfoP">
                            {" "}
                            To register for the webinar, click on the button
                            below. You will receive a confirmation email
                            with the webinar link and instructions a couple
                            days before the live webinar.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className=" faqDIVLeftRow">
                      <div className="faqDIVLeftRowIconDiv">
                        <GiCaduceus className="faqDIVLeftRowIcon" />
                      </div>
                      <div className="faqDIVLeftRowInfoDiv">
                        <div className="faqDIVLeftRowInfoHeadingDiv">
                          <h1 className="faqDIVLeftRowInfoHeading">
                            {" "}
                            How do I earn CEU credit from this webinar?
                          </h1>
                        </div>
                        <div className="faqDIVLeftRowInfoDivInfoDiv">
                          <p className="faqDIVLeftRowInfoDivInfoP">
                            {" "}
                            To earn CEU credit from this webinar, you need
                            to attend the live session or watch the
                            recording. You will also need to complete a
                            quiz. You will receive a certificate of
                            completion with 1 CEU credit from AAPC for this
                            Webinar.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className=" faqDIVLeftRow">
                      <div className="faqDIVLeftRowIconDiv">
                        <BsQuestionSquare className="faqDIVLeftRowIcon" />
                      </div>
                      <div className="faqDIVLeftRowInfoDiv">
                        <div className="faqDIVLeftRowInfoHeadingDiv">
                          <h1 className="faqDIVLeftRowInfoHeading">
                            {" "}
                            What if I have more questions about the webinar
                            or SDOH coding?
                          </h1>
                        </div>
                        <div className="faqDIVLeftRowInfoDivInfoDiv">
                          <p className="faqDIVLeftRowInfoDivInfoP">
                            If you have more questions about the webinar or
                            SDOH coding, you can contact us at
                            support@lmsrcm.com or +1 (302) 205 3970. Our
                            Speaker or available experts will answer all of
                            your questions.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="faqDIVRight"></div>
                </div>
              </div>
            </div>
          </div>

          <p className="Astrix">*The merchants represented are not sponsors of the rewards or otherwise affiliated with this company. The logos and other identifying marks attached are trademarks of and owned by each represented company and/or its affiliates.


          The Starbucks word mark and the Starbucks Logo are trademarks of Starbucks Corporation. Starbucks is also the owner of the Copyrights in the Starbucks Logo and the Starbucks Card designs. All rights reserved. Starbucks is not a participating partner or sponsor in this offer.
          
          Please visit each company's website for additional terms and conditions.</p>

        <div className="secondFoldNewSDOHPage">
  


        {/*  <div className="secondFoldNewSDOHPageRight">
            <div className="secondFoldNewSDOHPageRightCoffeeContainer">
            <h3 className="secondFoldNewSDOHPageRightCoffeeContainerHeading">Live<br></br>12 Months Recording<br></br>Free Starbucks Coffee Voucher </h3>
           <h1 className="secondFoldNewSDOHPageRightCoffeeContainerPrice">$99</h1>
            </div>
            <div className="secondFoldNewSDOHPageRightRegColumn">
              <p className="secondFoldNewSDOHPageRightRegistrationHeading">
            Please fill this form to Register
              </p>
              <div className="emailInputANdlineDivSDOH">
                <form ref={form} className="classForm" onSubmit={handleSubmit(
                  (data) => {
                    
                    sendEmail(data)
                    addDeatailsToEmailList(data);
                    setto_name('');
                    setto_email('');
                    setlastName('');
                    setComapnyName('');
                    setjobTitle('');
                    setTimeout(() => {
                      window.open("https://buy.stripe.com/6oE3fM7bvgDxg1ybIL", "_blank");
                    }, 3000);
                    console.log(data);})
                
                } autoComplete="off">
                  <InputText
                    type="text"
                    className="p-inputtext-lg"
                    placeholder="First Name"
                    style={{ margin: "20px", width: "80%" ,borderRadius:"5px"}}
                    name="to_name"
             
                    {...register("to_name", { required: true, })}
                  />
                  {errors.to_name && <p style={{color:"red",marginLeft:"20px"}}>*First name is required.</p>}
                  <InputText
                    type="text"
                    className="p-inputtext-lg"
                    placeholder="Last Name"
                    style={{ margin: "20px", width: "80%" ,borderRadius:"5px"}}
                 
                    {...register("lastName", { required: true, })}
                  />
                  {errors.lastName && <p style={{color:"red",marginLeft:"20px"}}>*Last name is required.</p>}
                  <InputText
                    type="email"
                    className="p-inputtext-lg"
                    placeholder="Email"
             
                    style={{ margin: "20px", width: "80%",borderRadius:"5px" }}
                    name="to_email"
                    {...register("to_email", { required: true, pattern:{
                      value:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      
                    } })}
                  />
                  {errors.to_email && <p style={{color:"red",marginLeft:"20px"}}>*Please enter a valid email</p>}

                  <InputText
                    type="text"
                    className="p-inputtext-lg"
                    placeholder="Company Name"
                  
                    style={{ margin: "20px", width: "80%",borderRadius:"5px" }}
                    {...register("companyName", { required: true })}
                  />
                  {errors.companyName && <p style={{color:"red",marginLeft:"20px"}}>*Company Name is required.</p>}

                  <InputText
                    type="text"
                    className="p-inputtext-lg"
                    placeholder="Job Title"
                   
                    style={{ margin: "20px", width: "80%",borderRadius:"5px" }}
                    {...register("jobTitle", { required: true })}
                  />
                  {errors.jobTitle && <p style={{color:"red",marginLeft:"20px"}}>*Job Title is required.</p>}

                  <ReCAPTCHA
                    sitekey="6LdE0wYoAAAAAEBNcoxtn1YCs9D8MUJIEdDz5GuJ"
                    onChange={onChange}
                 style={{marginTop:"10px",marginBottom:"10px"}}
                    />
           

                  <div className="checkBoxDiv">
                    <input type="checkbox" style={{ margin: "5px" }}
                     checked={addToEmailList}
                     
                    onChange={() => setAddToEmailList(!addToEmailList)} />
                    <p style={{ margin: "5px", fontSize: "12px" }}>
                      Add me to your mailing list for updates, offers and
                      rewards
                    </p>
                  </div>
                  <button
                    className="fifthFoldInfoContainerButton1"
                    style={{ margin: "20px" }}
                    value="Submit"
                  >
                    Submit
                  </button>
                  {/*<button className="firstFoldInfoContainerButton2">
Download Agenda
</button>

                  <p></p>
                </form>
              </div>
              <div></div>
            </div>
          </div>
*/}
        </div>
        <div  className="footerSDOH">
          <Footer />
        </div>
      </>
    );
  };

export default ElevatingEducationThroughAdvancedPromptsAJourneyIntoCreativeLearning;
