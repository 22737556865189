
import React, { useRef, useState } from "react";


// import "./styles.css";

// import required modules

import "./WebinarSlider.css";
import { Link } from "react-router-dom";
import NotifyMeModal from '../components/NotifyMeModal'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { EffectFade, Navigation, Pagination} from 'swiper/modules';
const WebinarSlider = () => {

  const [susanModal, setSusanModal] = useState(false)
  const closeSusanModal =()=>{
    setSusanModal(false);
  }

  return (
    <div className="webinarSliderContainer">
    {susanModal && <NotifyMeModal closeSusanModal={closeSusanModal} />}
      <h1 className="upcomingtrainingsHeading">Upcoming Trainings</h1>
    
      <Swiper
      spaceBetween={30}
      effect={'fade'}
      navigation={true}
      pagination={{
        clickable: true,
      }}
      modules={[EffectFade, Navigation, Pagination]}
      className="mySwiper"
      breakpoints={{
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 50,
        },
      }}
    >
    <SwiperSlide>
    <div className="firstWebinar">
    <div className="topImageOfWebinar">
      <img
        src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/images%2FFrame67.png?alt=media&token=80a6625c-737e-4a8e-8455-f36a9f61f4ea"
        alt=""
        className="topImagesize forresponsivenessFirstWebinar"
      />
      <img src="https://firebasestorage.googleapis.com/v0/b/promptifyer-6ab34.appspot.com/o/Speaker%20Profile%2Fcaitlin%20wham%2FCaitlinWhamSpeakerImage.png?alt=media&token=fbedb5a6-a6bf-41e7-a3fb-bf952f580c4f" alt="" className="webinarSpeakerInfoImg" />
      {/*<img src={Ellipse} alt='image'/>*/}
    </div>
    <div className="WebinarHeadingAndInfo">
      <p className="webinarHeading">Caitlin Wham, CCS</p>
 
      <p className="webinarHeadingActual">

<Link className="FirstWebinarLink" to="/sdoh" style={{textDecoration:"none",color:"#192551"}}>
      Social Determinants of Health: A Coding and Billing Guide
      </Link>
      </p>
 
      <p className="webinarTagline">
    
      </p>
    </div>
    <div className="webinarSpeakerInfo">
      <p className="nameOfSpeakerHeading">28nd September, 2023 </p>
      <p className="nameOfSpeakerCertification"><Link to="/sdoh"><button className="LearnMoreButton">Learn More</button></Link></p>
    </div>
  </div>
  </SwiperSlide>

  <SwiperSlide>
  <div className="secondWebinar">
  <div className="topImageOfWebinar">
  <img
    src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/images%2FFrame67.png?alt=media&token=80a6625c-737e-4a8e-8455-f36a9f61f4ea"
    alt=""
    className="topImagesize forresponsivenessFirstWebinar"
  />
  <img src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/ICD%2010%2FSiji_photo-removebg-preview.png?alt=media&token=47d2725d-d8c4-4acf-a0f1-e054623dab78" alt="" className="webinarSpeakerInfoImg" />
  {/*<img src={Ellipse} alt='image'/>*/}
</div>
<div className="WebinarHeadingAndInfo">
  <p className="webinarHeading">Siji Susan Joy,CPC</p>
  <p className="webinarHeadingActual"> 
  <Link className="FirstWebinarLink" to="/icd-10" style={{textDecoration:"none",color:"#192551"}}>
  Webinar: 2024 ICD-10 CM Changes and Updates
  </Link>
  </p>
  <p className="webinarTagline">

  </p>
</div>
<div className="webinarSpeakerInfo">
  <p className="nameOfSpeakerHeading">28nd September, 2023 </p>
  <p className="nameOfSpeakerCertification"><Link to="/icd-10"><button className="LearnMoreButton">Learn More</button></Link></p>
</div>
  </div>
</SwiperSlide>

<SwiperSlide>
<div className="firstWebinar">
<div className="topImageOfWebinar">
  <img
    src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/images%2FFrame67.png?alt=media&token=80a6625c-737e-4a8e-8455-f36a9f61f4ea"
    alt=""
    className="topImagesize forresponsivenessFirstWebinar"
  />
  <img src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/CDI%20Nurses%2FMarsha%20Image.jpg?alt=media&token=ebad9784-1974-448e-b96a-c37f41e3b4f0" alt="" className="webinarSpeakerInfoImg" />
  {/*<img src={Ellipse} alt='image'/>*/}
</div>
<div className="WebinarHeadingAndInfo">
  <p className="webinarHeading">Marsha Page, CCS-P</p>

  <p className="webinarHeadingActual">

<Link className="FirstWebinarLink" to="/cdinurses" style={{textDecoration:"none",color:"#192551"}}>
Key Coding Principles for CDI Nurses
  </Link>
  </p>

  <p className="webinarTagline">

  </p>
</div>
<div className="webinarSpeakerInfo">
  <p className="nameOfSpeakerHeading">Comig Soon</p>
  <p className="nameOfSpeakerCertification"><button className="LearnMoreButton"   onClick={() => setSusanModal(true)}>Notify Me</button></p>
</div>
</div>
</SwiperSlide>
    
 {/* <SwiperSlide>
<div className="secondFutureWebinar">
<div className="topImageOfFutureWebinars">
<img
  src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/images%2FFrame67.png?alt=media&token=80a6625c-737e-4a8e-8455-f36a9f61f4ea"
  alt=""
  className="topImagesize forresponsivenessFirstWebinar"
/>


</div>
<div className="futureWebinarHeadingAndInfo">

<p className="futureWebinarHeadingActual"> 
<Link className="FirstWebinarLink"  style={{textDecoration:"none",color:"#192551"}}>
FY 2024 ICD-10-PCS Updates
</Link>
</p>

</div>
<div className="webinarSpeakerInfo">
<p className="nameOfSpeakerHeading">Coming Soon </p>
<p className="nameOfSpeakerCertification"><button className="LearnMoreButton" onClick={() => setSusanModal(true)}>Notify Me</button></p>
</div>
</div>
</SwiperSlide>
<SwiperSlide>
<div className="firstFutureWebinar">
<div className="topImageOfFutureWebinars">
  <img
    src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/images%2FFrame67.png?alt=media&token=80a6625c-737e-4a8e-8455-f36a9f61f4ea"
    alt=""
    className="topImagesize forresponsivenessFirstWebinar"
  />


</div>
<div className="futureWebinarHeadingAndInfo">


  <p className="futureWebinarHeadingActual">

<Link className="FirstWebinarLink"  style={{textDecoration:"none",color:"#192551"}}>
Mastering Billing and Auditing for Transitional Care Management
  </Link>
  </p>


</div>
<div className="webinarSpeakerInfo">
  <p className="nameOfSpeakerHeading">Coming Soon</p>
  <p className="nameOfSpeakerCertification"><button className="LearnMoreButton" onClick={() => setSusanModal(true)}>Notify Me</button></p>
</div>
</div>
</SwiperSlide>


<SwiperSlide>
<div className="firstFutureWebinar">
<div className="topImageOfFutureWebinars">
  <img
    src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/images%2FFrame67.png?alt=media&token=80a6625c-737e-4a8e-8455-f36a9f61f4ea"
    alt=""
    className="topImagesize forresponsivenessFirstWebinar"
  />

</div>
<div className="futureWebinarHeadingAndInfo">


  <p className="futureWebinarHeadingActual">

<Link className="FirstWebinarLink"  style={{textDecoration:"none",color:"#192551"}}>
Modifier 59 and X Modifiers
  </Link>
  </p>


</div>
<div className="webinarSpeakerInfo">
  <p className="nameOfSpeakerHeading">Coming Soon</p>
  <p className="nameOfSpeakerCertification"><Link><button className="LearnMoreButton" onClick={() => setSusanModal(true)}>Notify Me</button></Link></p>
</div>
</div>
</SwiperSlide>

<SwiperSlide>
<div className="secondFutureWebinar">
<div className="topImageOfFutureWebinars">
<img
  src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/images%2FFrame67.png?alt=media&token=80a6625c-737e-4a8e-8455-f36a9f61f4ea"
  alt=""
  className="topImagesize forresponsivenessFirstWebinar"
/>

</div>
<div className="futureWebinarHeadingAndInfo">

<p className="futureWebinarHeadingActual"> 
<Link className="FirstWebinarLink" style={{textDecoration:"none",color:"#192551"}}>
CPT Assistant Updates
</Link>
</p>
<p className="webinarTagline">

</p>
</div>
<div className="webinarSpeakerInfo">
<p className="nameOfSpeakerHeading">Coming Soon</p>
<p className="nameOfSpeakerCertification"><Link ><button className="LearnMoreButton">Learn More</button></Link></p>
</div>
</div>
</SwiperSlide>

<SwiperSlide>
<div className="firstFutureWebinar">
<div className="topImageOfFutureWebinars">
  <img
    src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/images%2FFrame67.png?alt=media&token=80a6625c-737e-4a8e-8455-f36a9f61f4ea"
    alt=""
    className="topImagesize forresponsivenessFirstWebinar"
  />

</div>
<div className="futureWebinarHeadingAndInfo">


  <p className="futureWebinarHeadingActual">

<Link className="FirstWebinarLink"  style={{textDecoration:"none",color:"#192551"}}>
2024 CPT Updates
  </Link>
  </p>


</div>
<div className="webinarSpeakerInfo">
  <p className="nameOfSpeakerHeading">Coming Soon</p>
  <p className="nameOfSpeakerCertification"><button className="LearnMoreButton" onClick={() => setSusanModal(true)}>Notify Me</button></p>
</div>
</div>
</SwiperSlide>
    */}

    </Swiper>

    <div className="allWebinarsContainer">
      
       
    <div className="firstWebinar">
      <div className="topImageOfWebinar">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/images%2FFrame67.png?alt=media&token=80a6625c-737e-4a8e-8455-f36a9f61f4ea"
          alt=""
          className="topImagesize forresponsivenessFirstWebinar"
        />
        <img src="https://firebasestorage.googleapis.com/v0/b/promptifyer-6ab34.appspot.com/o/Speaker%20Profile%2Fcaitlin%20wham%2FCaitlinWhamSpeakerImage.png?alt=media&token=fbedb5a6-a6bf-41e7-a3fb-bf952f580c4f" alt="" className="webinarSpeakerInfoImg" />
        {/*<img src={Ellipse} alt='image'/>*/}
      </div>
      <div className="WebinarHeadingAndInfo">
        <p className="webinarHeading">Caitlin Wham, CCS</p>
   
        <p className="webinarHeadingActual">
 
<Link className="FirstWebinarLink" to="/sdoh" style={{textDecoration:"none",color:"#192551"}}>
        Social Determinants of Health: A Coding and Billing Guide
        </Link>
        </p>
   
        <p className="webinarTagline">
      
        </p>
      </div>
      <div className="webinarSpeakerInfo">
        <p className="nameOfSpeakerHeading">28th September, 2023 </p>
        <p className="nameOfSpeakerCertification"><Link to="/sdoh"><button className="LearnMoreButton">Learn More</button></Link></p>
      </div>
    </div>

    <div className="secondWebinar">
    <div className="topImageOfWebinar">
    <img
      src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/images%2FFrame67.png?alt=media&token=80a6625c-737e-4a8e-8455-f36a9f61f4ea"
      alt=""
      className="topImagesize forresponsivenessFirstWebinar"
    />
    <img src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/ICD%2010%2FSiji_photo-removebg-preview.png?alt=media&token=47d2725d-d8c4-4acf-a0f1-e054623dab78" alt="" className="webinarSpeakerInfoImg" />
    {/*<img src={Ellipse} alt='image'/>*/}
  </div>
  <div className="WebinarHeadingAndInfo">
    <p className="webinarHeading">Siji Susan Joy,CPC</p>
    <p className="webinarHeadingActual"> 
    <Link className="FirstWebinarLink" to="/icd-10" style={{textDecoration:"none",color:"#192551"}}>
    Webinar: 2024 ICD-10 CM Changes and Updates
    </Link>
    </p>
    <p className="webinarTagline">
  
    </p>
  </div>
  <div className="webinarSpeakerInfo">
    <p className="nameOfSpeakerHeading">28th September, 2023 </p>
    <p className="nameOfSpeakerCertification"><Link to="/icd-10"><button className="LearnMoreButton">Learn More</button></Link></p>
  </div>
    </div>
    <div className="firstWebinar">
    <div className="topImageOfWebinar">
      <img
        src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/images%2FFrame67.png?alt=media&token=80a6625c-737e-4a8e-8455-f36a9f61f4ea"
        alt=""
        className="topImagesize forresponsivenessFirstWebinar"
      />
      <img src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/CDI%20Nurses%2FMarsha%20Image.jpg?alt=media&token=ebad9784-1974-448e-b96a-c37f41e3b4f0" alt="" className="webinarSpeakerInfoImg" />
      {/*<img src={Ellipse} alt='image'/>*/}
    </div>
    <div className="WebinarHeadingAndInfo">
      <p className="webinarHeading">Marsha Page, CCS-P</p>
 
      <p className="webinarHeadingActual">

<Link className="FirstWebinarLink" to="/cdinurses" style={{textDecoration:"none",color:"#192551"}}>
Key Coding Principles for CDI Nurses
      </Link>
      </p>
 
      <p className="webinarTagline">
    
      </p>
    </div>
    <div className="webinarSpeakerInfo">
      <p className="nameOfSpeakerHeading">Comig Soon</p>
      <p className="nameOfSpeakerCertification"><button className="LearnMoreButton"   onClick={() => setSusanModal(true)}>Notify Me</button></p>
    </div>
  </div>
  </div>
  <div className="allWebinarsContainer">
  
   
  

  <div className="secondFutureWebinar">
  <div className="topImageOfFutureWebinars">
  <img
    src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/images%2FFrame67.png?alt=media&token=80a6625c-737e-4a8e-8455-f36a9f61f4ea"
    alt=""
    className="topImagesize forresponsivenessFirstWebinar"
  />

  {/*<img src={Ellipse} alt='image'/>*/}
</div>
<div className="futureWebinarHeadingAndInfo">

  <p className="futureWebinarHeadingActual"> 
  <Link className="FirstWebinarLink"  style={{textDecoration:"none",color:"#192551"}}>
  FY 2024 ICD-10-PCS Updates
  </Link>
  </p>

</div>
<div className="webinarSpeakerInfo">
  <p className="nameOfSpeakerHeading">Coming Soon </p>
  <p className="nameOfSpeakerCertification"><button className="LearnMoreButton" onClick={() => setSusanModal(true)}>Notify Me</button></p>
</div>
  </div>
  <div className="firstFutureWebinar">
  <div className="topImageOfFutureWebinars">
    <img
      src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/images%2FFrame67.png?alt=media&token=80a6625c-737e-4a8e-8455-f36a9f61f4ea"
      alt=""
      className="topImagesize forresponsivenessFirstWebinar"
    />

    {/*<img src={Ellipse} alt='image'/>*/}
  </div>
  <div className="futureWebinarHeadingAndInfo">


    <p className="futureWebinarHeadingActual">

<Link className="FirstWebinarLink"  style={{textDecoration:"none",color:"#192551"}}>
Mastering Billing and Auditing for Transitional Care Management
    </Link>
    </p>


  </div>
  <div className="webinarSpeakerInfo">
    <p className="nameOfSpeakerHeading">Coming Soon</p>
    <p className="nameOfSpeakerCertification"><button className="LearnMoreButton" onClick={() => setSusanModal(true)}>Notify Me</button></p>
  </div>
</div>
</div>
<div className="allWebinarsContainer">
  
<div className="firstFutureWebinar">
<div className="topImageOfFutureWebinars">
  <img
    src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/images%2FFrame67.png?alt=media&token=80a6625c-737e-4a8e-8455-f36a9f61f4ea"
    alt=""
    className="topImagesize forresponsivenessFirstWebinar"
  />

  {/*<img src={Ellipse} alt='image'/>*/}
</div>
<div className="futureWebinarHeadingAndInfo">


  <p className="futureWebinarHeadingActual">

<Link className="FirstWebinarLink"  style={{textDecoration:"none",color:"#192551"}}>
Modifier 59 and X Modifiers
  </Link>
  </p>


</div>
<div className="webinarSpeakerInfo">
  <p className="nameOfSpeakerHeading">Coming Soon</p>
  <p className="nameOfSpeakerCertification"><Link><button className="LearnMoreButton" onClick={() => setSusanModal(true)}>Notify Me</button></Link></p>
</div>
</div>
  

<div className="secondFutureWebinar">
<div className="topImageOfFutureWebinars">
<img
  src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/images%2FFrame67.png?alt=media&token=80a6625c-737e-4a8e-8455-f36a9f61f4ea"
  alt=""
  className="topImagesize forresponsivenessFirstWebinar"
/>

{/*<img src={Ellipse} alt='image'/>*/}
</div>
<div className="futureWebinarHeadingAndInfo">

<p className="futureWebinarHeadingActual"> 
<Link className="FirstWebinarLink" style={{textDecoration:"none",color:"#192551"}}>
CPT Assistant Updates
</Link>
</p>
<p className="webinarTagline">

</p>
</div>
<div className="webinarSpeakerInfo">
<p className="nameOfSpeakerHeading">Coming Soon</p>
<p className="nameOfSpeakerCertification"><Link ><button className="LearnMoreButton">Learn More</button></Link></p>
</div>
</div>
<div className="firstFutureWebinar">
<div className="topImageOfFutureWebinars">
  <img
    src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/images%2FFrame67.png?alt=media&token=80a6625c-737e-4a8e-8455-f36a9f61f4ea"
    alt=""
    className="topImagesize forresponsivenessFirstWebinar"
  />

  {/*<img src={Ellipse} alt='image'/>*/}
</div>
<div className="futureWebinarHeadingAndInfo">


  <p className="futureWebinarHeadingActual">

<Link className="FirstWebinarLink"  style={{textDecoration:"none",color:"#192551"}}>
2024 CPT Updates
  </Link>
  </p>


</div>
<div className="webinarSpeakerInfo">
  <p className="nameOfSpeakerHeading">Coming Soon</p>
  <p className="nameOfSpeakerCertification"><button className="LearnMoreButton" onClick={() => setSusanModal(true)}>Notify Me</button></p>
</div>
</div>
</div>


    </div>
  );
};

export default WebinarSlider;
