import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import "./Agenda.css";
const JoinModal = ({ closeJoinModal }) => {
    return createPortal(
        <>
            <div className="modalWrapper" onClick={closeJoinModal}>

                <div className="ModalContainer">
                    <div className="ModalConatinerHeadingDiv">
                        <h1 className="ModalConatinerHeading">
    Check Your Inbox
                        </h1>
                    </div>
                    <div className="AgendaDeatilsConatiner">
                        <p className="AgendaDeatilsHeadings"> You have been sent an email. If you are not able to find it in inbox then please check your spam folder and mark our email as important!</p>

                    </div>
                </div>
            </div>
        </>,
        document.querySelector(".MyModalDiv")
    );
};

export default JoinModal;