import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import "./Webinar.css";
import { InputText } from "primereact/inputtext";
import Footer from "../components/Footer";
import Navbar2 from "../components/Navbar2";
import { Link } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  FcQuestions,
  FcVoicePresentation,
  FcKey,
  FcReading,
  FcRefresh,
  FcTodoList,
  FcFinePrint,
  FcCamcorderPro,
} from "react-icons/fc";
import { SiStarbucks } from "react-icons/si";

import { BsBookmarkHeart } from "react-icons/bs";
import { MdOutlineLiveTv, MdOutlineTimelapse } from "react-icons/md";
import { FiUserCheck } from "react-icons/fi";
import { BsPatchQuestion } from "react-icons/bs";
import { RxPencil2 } from "react-icons/rx";
import { GiArchiveRegister } from "react-icons/gi";
import { GiCaduceus } from "react-icons/gi";
import { BsQuestionSquare } from "react-icons/bs";
import ScrollAnimation from "react-animate-on-scroll";
import { AiOutlineDollar } from "react-icons/ai";
import Agenda from "../components/Agenda";
import SpeakerModal from "../components/SpeakerModal";
import JoinModal from "../components/JoinModal";
import {collection,addDoc} from 'firebase/firestore'
import { fs } from "../firebase";

const Webinar = () => {
  const [inputMailList, setInputMailList] = useState("");
  const [inputJoinList, setInputJoinList] = useState("");
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [showAgenda, setShowAgenda] = useState(false)
  const [showSpeakerModal, setShowSpeakerModal] = useState(false)
  const [showJoinModal, setShowJoinModal] = useState(false)
  const [emailTop, setEmailTop] = useState("")
  const [successMsg, setSuccessmsg] = useState("");
  const [error, setError] = useState("");

  const notify = () => {
    toast.success("You are successfully added to our emailing list!", {
      position: toast.POSITION.TOP_CENTER
    });
    ;}
  const addDeatailsToEmailList = async(e)=>{

    e.preventDefault();
    // console.log(fname,lname,email,certification,phone,time,date,topicOfInterest)
    
    notify();

    const docRef = await addDoc(collection(fs, "SDOHWebinarEmailList"), {
      
      emailTop
    }).then(()=>{
      setSuccessmsg('Your are successfully added to our emailing list!');
      setEmailTop('')
      setError('');
      setTimeout(()=>{
setSuccessmsg('');
      },3000)
           
    }).catch((error)=>setError(error.message));
    setShowJoinModal(true)
  }
  const handleImageChange = (index) => {
    setSelectedImageIndex(index);
  };

  const closeModal = () => {
    setShowAgenda(false);
  }
  const closeJoinModal = () => {
    setShowJoinModal(false);
    setInputJoinList('')
  }
  const closeSpeakerModal = () => {
    setShowSpeakerModal(false);
  }

  const imageUrls = [
    "https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/SDOH%2FDreamShaper_v7_Images_for_Social_determinants_of_health_webina_3.jpg?alt=media&token=f820b763-b6e2-4284-a475-1dbd1cfd156b",
    "https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/SDOH%2FDreamShaper_v7_Images_for_Social_determinants_of_health_webina_5.jpg?alt=media&token=78198f64-93b2-4f27-816e-fdf406ae2121",
    "https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/SDOH%2FDreamShaper_v7_Images_for_Social_determinates_of_health_webina_0.jpg?alt=media&token=dd3e4ac1-cfc4-45d0-a978-face41f08c7b",
    "https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/SDOH%2FDreamShaper_v7_Images_for_Social_determinates_of_health_webina_3.jpg?alt=media&token=71e4fcab-bf06-445a-9e2e-c1793944d76a",
    "https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/SDOH%2FDreamShaper_v7_Images_for_Social_determinants_of_health_webina_1.jpg?alt=media&token=77a02815-a5e9-4634-854b-f7477beed3d5",
    "https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/SDOH%2FDreamShaper_v7_Images_for_Social_determinants_of_health_webina_3%20(1).jpg?alt=media&token=9eca379f-a5a6-438d-ac24-26500be4b1cd",
  ];

  return (
    <>

      <div className="WebinarContainer">
      <ToastContainer />
        <div className="navbarContainer">
          <Navbar2 />
        </div>

        {showAgenda && (
          <Agenda closeModal={closeModal} />
        )}
        {showSpeakerModal && (
          <SpeakerModal closeSpeakerModal={closeSpeakerModal} />
        )}

        {showJoinModal && (
          <JoinModal closeJoinModal={closeJoinModal} />
        )}
        <div className="sdohbackgroundImageConatiner">
        
        </div>
        <div className="firstFoldWebinarContainer">
          <div className="WebinarFullDetailsColumnLeft">
            <div className="firstFoldInfoContainer">
              <p className="firstFoldInfoContainerTagline">
                Social Determinants Of Health:<br></br> A Coding And Billing Guide
              </p>
              <p className="firstFoldInfoContainerfirstpara">



                Unleash Health Equity: Mastering SDOH Coding - Join our expert-led webinar to gain essential skills for accurately coding Social Determinants of Health (SDOH). Elevate your expertise and make a positive impact on healthcare outcomes. Secure your spot now

              </p>
              <div className="firstFoldInfoContainerButtonDiv">
                <button className="fifthFoldInfoContainerButton1">
                  <a target="_blank" href="https://buy.stripe.com/6oE3fM7bvgDxg1ybIL" className="fifthFoldInfoContainerButton1Link" >Register Now</a>

                </button>
                {/*<button className="firstFoldInfoContainerButton2">
 Download Agenda
</button>*/}
              </div>
              <a href="/medical-coding-education/"><img src="https://static.aapc.com/aapc/images/aapc-ceu-approved-200x74.gif" alt="AAPC Medical Coding CEU Approved Vendor" width="200" height="74" border="0" style={{ borderRadius: "5px" }} className="kababmehaddi" /></a>
              <p className="firstFoldInfoContainerfirstLastpara">
                | 15<sup>th</sup> September, 2023| 3:00pm ET |
              </p>

              <div className="StripeQRCodeContainer">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/SDOH%2FSDOH_QR_Code.png?alt=media&token=5e3f8d3e-972f-46bb-b75d-52dc7a42e1c4"
                  alt="qr code"
                  className="StripeQRCodeContainerImg"
                />
              </div>
            </div>
          </div>
          <div className="WebinarBuyingInfoContainer">
            <div className="WebinarBuyingInfoContainerWrapper">

            <div className="WebinarBuyingInfoContainerDetailsICD10Coffee">

            <div className="WebinarBuyingInfoContainerDetailsICD10CoffeeIconContainer">

            <div className="WebinarBuyingInfoContainerDetailsICD10CoffeeIconContainerFreeImageDiv">
                 <img src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/ICD%2010%2Ffreegif-removebg-preview.png?alt=media&token=e0eb5fc3-0919-4372-8bb2-11dd3e7febae"  className="WebinarBuyingInfoContainerDetailsICD10CoffeeIconContainerFreeImage"/>
                 </div>
                 <SiStarbucks style={{fontSize:"120px",  color:"#036635",width:"35%"}}/>
            </div>
            <p className="WebinarBuyingInfoContainerDetailsICD10CoffeeH1">Free <span style={{color:"#036635"}}>Starbucks Coffee Voucher </span>For Every webinar Attendee</p>
         
             </div>
             <div className="WebinarBuyingInfoContainerDetailsICD10Coffee2">

             <p className="WebinarBuyingInfoContainerDetailsICD10Coffeebelowp">Sponserd By</p>
             <a href="https://www.kickstarter.com/projects/enprompt360/enprompt-360?utm_source=website&utm_medium=icdweb&utm_id=icd10" style={{width:"100%",height:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}>
         <img src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/ICD%2010%2FLogoICD10Enprompt360-removebg-preview.png?alt=media&token=02a80c57-4b3b-403a-af11-d0fcb29a88e3" style={{width:"70%",height:"86%"}}/>
              </a>
              </div>
           
              {/* <div className="addToWishListDiv" onClick={()=>setShowAgenda(true)} >
              <BsBookmarkHeart className="addToWishListIcon" />

              <p className="addToWishListLine"> Download Agenda</p>
            </div> */}
              <div className="WebinarBuyingInfoContainerDetails">

                <a target="_blank" href="https://buy.stripe.com/6oE3fM7bvgDxg1ybIL" className="fifthFoldInfoContainerButton1Link2" >
                  <div className="WebinarBuyingInfoContainerDetailsBuyButton">
                    <p className="WebinarBuyingInfoContainerDetaiclsBuyButtonP">Buy Now
                    </p>
                    <p className="WebinarBuyingInfoContainerDetaiclsBuyButtonPriceP">
                      $ 99
                    </p>
                    <p className="WebinarBuyingInfoContainerDetaiclsBuyButtonPriceUpperHeading">
                      Signup for Early Bird
                    </p>
                  </div>
                </a>
                <div className="WebinarBuyingInfoContainerDetailsPart2Div">
                  <div className="WebinarBuyingInfoContainerDetailsPart2DivRow">
                    <p className="WebinarBuyingInfoContainerDetailsPart2DivRowP">
                      Duration:
                      <span className="WebinarBuyingInfoContainerDetailsPart2DivRowSpan">
                        60-70 mins
                      </span>
                    </p>
                  </div>
                  <div className="WebinarBuyingInfoContainerDetailsPart2DivRow">
                    <p className="WebinarBuyingInfoContainerDetailsPart2DivRowP">
                      Lectures:
                      <span className="WebinarBuyingInfoContainerDetailsPart2DivRowSpan">
                        1
                      </span>
                    </p>
                  </div>
                  <div className="WebinarBuyingInfoContainerDetailsPart2DivRow">
                    <p className="WebinarBuyingInfoContainerDetailsPart2DivRowP">
                      Level:
                      <span className="WebinarBuyingInfoContainerDetailsPart2DivRowSpan2">
                        AAPC
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="WebinarBuyingInfoContainerDetailsSpeaker
              ">
                <div className=" WebinarBuyingInfoContainerDetailsSpeakerFirstFold">
                  <div className="speakerContainerDetails">
                    <div className="speakerContainerDetailsHeadingDiv"
                    >
                      <h1 className="speakerContainerDetailsHeading" > Caitlin Wham
                        <br />

                        <p className="speakerContainerDetailsHeadingCertification">CCS</p>
                      </h1>
                      <p className="speakerContainerDetailsHeadingreadMore" onClick={() => setShowSpeakerModal(true)}>Read More</p>
                    </div>

                  </div>
                  <div className="speakerImageContainer">
                    <img src="https://firebasestorage.googleapis.com/v0/b/promptifyer-6ab34.appspot.com/o/Speaker%20Profile%2Fcaitlin%20wham%2FCaitlinWhamSpeakerImage.png?alt=media&token=fbedb5a6-a6bf-41e7-a3fb-bf952f580c4f" className="speakerImage" alt=" speaker image" />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="secondFoldWebinarContainer">
          <div className="SecondFoldBothInfoAndInputConatiner">
            <div className="secondFoldWebinarContainerInfo">
              <p className="secondFoldWebinarContainerInfoTagline">
                Join our mailinglist now for{" "}
                <span className="secondFoldWebinarContainerInfoTaglineImportant">
                  Updates, Agendas, Reminders
                </span>
              </p>
            </div>
            <div className="secondFoldWebinarContainerInputTextDiv">
              <InputText
                value={inputMailList}
                onChange={(e) => setInputMailList(e.target.value)}
                className="secondFoldWebinarContainerInputText"
                placeholder="john@riverdale.com"
              />
              <button className="firstFoldInfoContainerButton2">Join now!</button>
            </div>
          </div>
        </div>
        <div className="ThirdFoldContainer">
        <div className="ThirdFold">
        <div className="thirdFoldWebinarContainer">
          {/* */}  <div className="DiveAboveSessionHighlights">
            <p className="DiveAboveSessionHighlightsP" >
              <h1 className="thirdFoldWebinarContainerCenterdivAbovePP">
                The SDOH Training
              </h1>
              <br></br>
              - Are you ready to master the art of SDOH coding?
              <br></br>
              <br></br>
              -  Coding for SDOH is essential for capturing the patient's health
              status, identifying gaps in care, informing interventions,
              measuring outcomes and supporting value-based payment models. But coding for SDOH can be tricky. You need to know where to find
              the relevant information in the medical record, how to apply the
              official coding guidelines and standards, and how to keep up with
              the changes and updates in SDOH coding.
              <br></br>
              <br></br>
              - Join us on Social Determinants of Health: A Coding Guide.
              <br></br>
              <br></br>
            </p>
          </div>
          <div className="sessionHighlights">
            <div className="sessionHighlightsDivHeading">
              <h1 className="thirdFoldWebinarContainerCenterdivAboveP">
                Session Highlights
              </h1>
            </div>
            <div className="sessionHighlightsInfo">
              {/*  <div className="left">
                    <img
                      src={imageUrls[selectedImageIndex]}
                      alt="Session Highlight"
                      className="highlightImage"
                    />
</div>*/}
              <div className="right">
                <div className="row">
                  <button
                    className="imageButton"
                    onClick={() => handleImageChange(1)}
                  ></button>
                  <p style={{ fontSize: "24px" }}>
                    What are SDOH and why they matter for health care quality and
                    equity
                  </p>
                </div>
                <div className="row">
                  <button
                    className="imageButton"
                    onClick={() => handleImageChange(1)}
                  ></button>
                  <p>
                    The coding professional’s role in SDOH data capture and
                    reporting
                  </p>
                </div>
                <div className="row">
                  <button
                    className="imageButton"
                    onClick={() => handleImageChange(2)}
                  ></button>
                  <p>The 5 key areas of SDOH and their corresponding codes</p>
                </div>
                <div className="row">
                  <button
                    className="imageButton"
                    onClick={() => handleImageChange(3)}
                  ></button>
                  <p>
                    The official coding guidance on SDOH from ICD-10-CM, Z codes
                    and other sources
                  </p>
                </div>
                <div className="row">
                  <button
                    className="imageButton"
                    onClick={() => handleImageChange(4)}
                  ></button>
                  <p>
                    The 2023 updates to SDOH coding and what they mean for your
                    practice
                  </p>
                </div>
                <div className="row">
                  <button
                    className="imageButton"
                    onClick={() => handleImageChange(0)}
                  ></button>
                  <p>
                    Where to locate SDOH in the medical record and how to code
                    them accurately and consistently
                  </p>
                </div>
                <div className="row">
                  <button
                    className="imageButton"
                    onClick={() => handleImageChange(3)}
                  ></button>
                  <p>Examples of SDOH coding scenarios and best practices</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>
        
        <div className="FifthFdoldWebinarContainer">


          <div className="fifthFoldInfoContainerButtonDiv">
            <button className="fifthFoldInfoContainerButton">           <a target="_blank" href="https://buy.stripe.com/6oE3fM7bvgDxg1ybIL" className="fifthFoldInfoContainerButton1Link" >Register Now</a></button>
            {/* <button className="firstFoldInfoContainerButton2">
     Download Agenda
</button>*/}
          </div>
        </div>
        <div className="ThirdFoldContainer">
        <div className="ThirdFold">
        <div className="FourthFoldWebinarContainer">
          <div className="FourthFoldWebinarContainerHeadingDiv">
            <h1 className="FourthFoldWebinarContainerHeading">
              <p className="FourthFoldWebinarContainerHeadingFAQ"> </p>
              <ScrollAnimation
                duration={5}
                animateIn="hinge"
                initiallyVisible={true}
              >
                <p className="FourthFoldWebinarContainerHeadingFAQ">
                  Frequently Asked Questions
                </p>
              </ScrollAnimation>
              <span className="FourthFoldWebinarContainerHeadingTagline">
                Quick answers to questions you may have.Cant't find what you are
                looking for?{" "}
                <a className="FourthFoldWebinarContainerHeadingTaglineLink">
                  Get in touch.
                </a>
              </span>
            </h1>
          </div>
          <div className="faqDIV">
            <div className="faqDIVLeft">
              <div className=" faqDIVLeftRow">
                <div className="faqDIVLeftRowIconDiv">
                  <MdOutlineLiveTv className="faqDIVLeftRowIcon" />
                </div>
                <div className="faqDIVLeftRowInfoDiv">
                  <div className="faqDIVLeftRowInfoHeadingDiv">
                    <h1 className="faqDIVLeftRowInfoHeading">
                      {" "}
                      What if I can not attend live?
                    </h1>
                  </div>
                  <div className="faqDIVLeftRowInfoDivInfoDiv">
                    <p className="faqDIVLeftRowInfoDivInfoP">
                      You’ll have access to recording(On demand) and transcript
                      for 12 months. You can still ask questions to the Speaker
                      via email.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" faqDIVLeftRow">
                <div className="faqDIVLeftRowIconDiv">
                  <BsPatchQuestion className="faqDIVLeftRowIcon" />
                </div>
                <div className="faqDIVLeftRowInfoDiv">
                  <div className="faqDIVLeftRowInfoHeadingDiv">
                    <h1 className="faqDIVLeftRowInfoHeading">
                      What is the purpose of this webinar?
                    </h1>
                  </div>
                  <div className="faqDIVLeftRowInfoDivInfoDiv">
                    <p className="faqDIVLeftRowInfoDivInfoP">
                      {" "}
                      The purpose of this webinar is to teach you how to code for
                      social determinants of health (SDOH) and improve the quality
                      of care for your patients.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" faqDIVLeftRow">
                <div className="faqDIVLeftRowIconDiv">
                  <FiUserCheck className="faqDIVLeftRowIcon" />
                </div>
                <div className="faqDIVLeftRowInfoDiv">
                  <div className="faqDIVLeftRowInfoHeadingDiv">
                    <h1 className="faqDIVLeftRowInfoHeading">
                      Who is this webinar for?
                    </h1>
                  </div>
                  <div className="faqDIVLeftRowInfoDivInfoDiv">
                    <p className="faqDIVLeftRowInfoDivInfoP">
                      {" "}
                      This webinar is for coding professionals who want to enhance
                      their skills and knowledge in SDOH coding. It is also
                      suitable for anyone who is interested in learning more about
                      SDOH and their impact on health care.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" faqDIVLeftRow">
                <div className="faqDIVLeftRowIconDiv">
                  <GiArchiveRegister className="faqDIVLeftRowIcon" />
                </div>
                <div className="faqDIVLeftRowInfoDiv">
                  <div className="faqDIVLeftRowInfoHeadingDiv">
                    <h1 className="faqDIVLeftRowInfoHeading">
                      {" "}
                      What will I learn from this webinar?
                    </h1>
                  </div>
                  <div className="faqDIVLeftRowInfoDivInfoDiv">
                    <p className="faqDIVLeftRowInfoDivInfoP">
                      {" "}
                      In this webinar, you will learn:
                      <br></br>
                      - What are SDOH and why they matter for health care quality
                      and equity
                      <br />
                      - The coding professional’s role in SDOH data capture and
                      reporting
                      <br />
                      - The 5 key areas of SDOH and their corresponding codes
                      <br />
                      - The official coding guidance on SDOH from ICD-10-CM, Z
                      codes and other sources
                      <br />
                      - The 2023 updates to SDOH coding and what they mean for
                      your practice
                      <br />
                      - Where to locate SDOH in the medical record and how to code
                      them accurately and consistently
                      <br />- Examples of SDOH coding scenarios and best practices
                    </p>
                  </div>
                </div>
              </div>
              <div className=" faqDIVLeftRow">
                <div className="faqDIVLeftRowIconDiv">
                  <MdOutlineTimelapse className="faqDIVLeftRowIcon" />
                </div>
                <div className="faqDIVLeftRowInfoDiv">
                  <div className="faqDIVLeftRowInfoHeadingDiv">
                    <h1 className="faqDIVLeftRowInfoHeading">
                      {" "}
                      How long is the webinar?
                    </h1>
                  </div>
                  <div className="faqDIVLeftRowInfoDivInfoDiv">
                    <p className="faqDIVLeftRowInfoDivInfoP">
                      {" "}
                      The webinar is about 60 minutes long, including Q&A
                      sessions.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" faqDIVLeftRow">
                <div className="faqDIVLeftRowIconDiv">
                  <AiOutlineDollar className="faqDIVLeftRowIcon" />
                </div>
                <div className="faqDIVLeftRowInfoDiv">
                  <div className="faqDIVLeftRowInfoHeadingDiv">
                    <h1 className="faqDIVLeftRowInfoHeading">
                      How much does the webinar cost?
                    </h1>
                  </div>
                  <div className="faqDIVLeftRowInfoDivInfoDiv">
                    <p className="faqDIVLeftRowInfoDivInfoP">
                      {" "}
                      The registration fee for the webinar is $99.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" faqDIVLeftRow">
                <div className="faqDIVLeftRowIconDiv">
                  <RxPencil2 className="faqDIVLeftRowIcon" />
                </div>
                <div className="faqDIVLeftRowInfoDiv">
                  <div className="faqDIVLeftRowInfoHeadingDiv">
                    <h1 className="faqDIVLeftRowInfoHeading">
                      {" "}
                      How do I register for the webinar?
                    </h1>
                  </div>
                  <div className="faqDIVLeftRowInfoDivInfoDiv">
                    <p className="faqDIVLeftRowInfoDivInfoP">
                      {" "}
                      To register for the webinar, click on the button below. You
                      will receive a confirmation email with the webinar link and
                      instructions a couple days before the live webinar.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" faqDIVLeftRow">
                <div className="faqDIVLeftRowIconDiv">
                  <GiCaduceus className="faqDIVLeftRowIcon" />
                </div>
                <div className="faqDIVLeftRowInfoDiv">
                  <div className="faqDIVLeftRowInfoHeadingDiv">
                    <h1 className="faqDIVLeftRowInfoHeading">
                      {" "}
                      How do I earn CEU credit from this webinar?
                    </h1>
                  </div>
                  <div className="faqDIVLeftRowInfoDivInfoDiv">
                    <p className="faqDIVLeftRowInfoDivInfoP">
                      {" "}
                      To earn CEU credit from this webinar, you need to attend the
                      live session or watch the recording. You will also need to
                      complete a quiz. You will receive a certificate of
                      completion with 1 CEU credit from AAPC for this Webinar.
                    </p>
                  </div>
                </div>
              </div>

              <div className=" faqDIVLeftRow">
                <div className="faqDIVLeftRowIconDiv">
                  <BsQuestionSquare className="faqDIVLeftRowIcon" />
                </div>
                <div className="faqDIVLeftRowInfoDiv">
                  <div className="faqDIVLeftRowInfoHeadingDiv">
                    <h1 className="faqDIVLeftRowInfoHeading">
                      {" "}
                      What if I have more questions about the webinar or SDOH
                      coding?
                    </h1>
                  </div>
                  <div className="faqDIVLeftRowInfoDivInfoDiv">
                    <p className="faqDIVLeftRowInfoDivInfoP">
                      If you have more questions about the webinar or SDOH coding,
                      you can contact us at support@lmsrcm.com or +1 (302) 205 3970. Our Speaker or
                      available experts will answer all of your questions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="faqDIVRight"></div>
          </div>
        </div>
        </div>
        </div>


        <div className="footerContainerWebinarContainer">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Webinar;