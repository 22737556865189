import React from 'react'
import { Link } from 'react-router-dom'
import './Thankyou.css'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar2'


const Thankyou = () => {
  return (
    <div className="ThankyoupageConatiner">
    <div className="navbarContainer">
    <Navbar />
  </div>
    <div className="ThankyoupageConatinerImgDiv">
    <img src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/ICD%2010%2FthankyouImage.jpg?alt=media&token=e9a2dda3-cdf4-48d6-ae0f-d013bba97e5d" className='ThankyoupageConatinerImg'/>

    </div>

    <h1 className="ThankyoupageConatinerHeading">Your Starbucks coffee voucher will be emailed to you within the next 24 hours.</h1>
    <h3 className="ThankyoupageConatinerHeading2">Your webinar attendee link will be shared with you 3 days prior to the webinar</h3>
    
    <div className="ThankyoupageConatinerOtherWebinarsDiv">
    <h3 className="ThankyoupageConatinerHeading2">You may also be intrested in :</h3>
    <div className="firstWebinar">
    <div className="topImageOfWebinar">
      <img
        src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/images%2FFrame67.png?alt=media&token=80a6625c-737e-4a8e-8455-f36a9f61f4ea"
        alt=""
        className="topImagesize forresponsivenessFirstWebinar"
      />
      <img src="https://firebasestorage.googleapis.com/v0/b/promptifyer-6ab34.appspot.com/o/Speaker%20Profile%2Fcaitlin%20wham%2FCaitlinWhamSpeakerImage.png?alt=media&token=fbedb5a6-a6bf-41e7-a3fb-bf952f580c4f" alt="" className="webinarSpeakerInfoImg" />
      {/*<img src={Ellipse} alt='image'/>*/}
    </div>
    <div className="WebinarHeadingAndInfo">
      <p className="webinarHeading">Caitlin Wham, CCS</p>
 
      <p className="webinarHeadingActual">

<Link className="FirstWebinarLink" to="/webinar" style={{textDecoration:"none",color:"#192551"}}>
      Social Determinants of Health: A Coding and Billing Guide
      </Link>
      </p>
 
      <p className="webinarTagline">
    
      </p>
    </div>
    <div className="webinarSpeakerInfo">
      <p className="nameOfSpeakerHeading">8th September, 2023 </p>
      <p className="nameOfSpeakerCertification"></p>
    </div>
  </div>

    </div>
    <Footer/>
    </div>
  )
}

export default Thankyou