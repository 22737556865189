import React from 'react'
import './specialitiesAndTrainingsContainer.css'
import WebinarSlider from './WebinarSlider'
const SpecialitiesAndTrainingsContainer = () => {
  return (
    <div className="specialitiesAndTrainingsContainer">
 

    <WebinarSlider/>

    

    
    </div>
  )
}

export default SpecialitiesAndTrainingsContainer