import React from "react";

import {VscLocation} from 'react-icons/vsc'
import { Link } from "react-router-dom";
import './Footer.css'
import { useState } from "react";
import { async } from "@firebase/util";
import {collection,addDoc} from 'firebase/firestore'
import { fs } from "../firebase";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Footer = () => {
  const [emailFooter, setEmailFooter] = useState("")
  const [successMsg, setSuccessmsg] = useState("");
  const [error, setError] = useState("");

  const notify = () => {
    toast.success("You are successfully added to our emailing list!", {
      position: toast.POSITION.TOP_CENTER
    });
    ;}
  const addDeatailsToEmailList = async(e)=>{

    e.preventDefault();
    // console.log(fname,lname,email,certification,phone,time,date,topicOfInterest)
    
    notify();
    const docRef = await addDoc(collection(fs, "newEmailList"), {
      
      emailFooter
    }).then(()=>{
      setSuccessmsg('Your are successfully added to our emailing list!');
      setEmailFooter('')
      setError('');
      setTimeout(()=>{
setSuccessmsg('');
      },3000)
           
    }).catch((error)=>setError(error.message));
  }

  return (
    <div className="FooterContainer">
    <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
{/* Same as */}
<ToastContainer />
      <div className="footerLogoSection">
        <div className="FooterLogo"><img  src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/images%2FLogoNavbar%20(1).png?alt=media&token=37384f28-d7f9-4ff8-b1ec-aecf642d6c37" alt="logo"  className="footerLogoImgSize"/> </div>
        <div className="FooterLogoInfo">© 2023 LMSRCM</div>
        <div className="adressforFooter">
              <VscLocation className="footerAddressIcon" />
              <p className="footerAddress">
                8 The Green Suite,8068<br></br>
                Dover,Delaware<br></br>
                19901
              </p>
            </div>
        <div className="linksToPrivacyAndTerms"><Link className="ActualinkToTermsAndPrivacy" to='/privacyPolicy'>Privacy Policy </Link> | <Link className="ActualinkToTermsAndPrivacy" to='/trainingTermsOfUse'>Training Terms Of Use</Link></div>
      </div>
      <div className="footerLogoMenu">
        <div className="FooterHome"><Link className="ActualinkToTermsAndPrivacy" to='/'>Home</Link></div>
      
        <div className="FooterHome">LMS</div>
        <div className="FooterHome">Webinars</div>
        <Link to="/contactUs" className="hamburgerWebinarsLink">
        <div className="FooterHome">Become a speaker</div></Link>

       


      </div>
      <div className="footermainlingList">

      <p className="joinMailingList"> Join our mailing list</p>
      
      <input
      type="email"
      placeholder="Enter your email"
      className="emailAdressFooterEmailingList"
      onChange={(e) => setEmailFooter(e.target.value)}
      value={emailFooter}
    />
    <button type="submit" className="SubmitButtonForEmailList" onClick={addDeatailsToEmailList}>Submit</button>
      </div>
    </div>
  );
};

export default Footer;
