import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import "./Agenda.css";
const Agenda = ({ closeSusanAgendaModal }) => {
  useEffect(() => {
    document.body.style.overflowY = "hidden";

    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, []);

  return createPortal(
    <>
      <div className="modalWrapper" onClick={closeSusanAgendaModal}></div>
      <div className="ModalContainer">
        <div className="ModalConatinerHeadingDiv">
          <h1 className="ModalConatinerHeading">
            Outline for a training curriculum on 2024 ICD-10 CM Changes and Updates:
          </h1>
        </div>
        <div className="AgendaDeatilsConatiner">
          <p className="AgendaDeatilsHeadings">1. Introduction</p>
          <p className="AgendaDetailPoints">
            {" "}
           Lorem ipsum dolor sit amet consectetur adipisicing elit. Non odio possimus quo quis praesentium, quam reiciendis sed eligendi! Vitae nihil voluptatum atque expedita nesciunt. Voluptatibus explicabo asperiores nisi ab? Rerum.
          </p>
          <p className="AgendaDeatilsHeadings">
            {" "}
            2.      Lorem ipsum dolor sit amet consectetur adipisicing elit. Non odio possimus quo quis praesentium, quam reiciendis sed eligendi! Vitae nihil voluptatum atque expedita nesciunt. Voluptatibus explicabo asperiores nisi ab? Rerum.
          </p>
          <p className="AgendaDetailPoints">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Non odio possimus quo quis praesentium, quam reiciendis sed eligendi! Vitae nihil voluptatum atque expedita nesciunt. Voluptatibus explicabo asperiores nisi ab? Rerum.
          </p>
          <p className="AgendaDeatilsHeadings">
            3.     Lorem ipsum dolor sit amet consectetur adipisicing elit. Non odio possimus quo quis praesentium, quam reiciendis sed eligendi! Vitae nihil voluptatum atque expedita nesciunt. Voluptatibus explicabo asperiores nisi ab? Rerum.
          </p>
          <p className="AgendaDetailPoints">
            {" "}
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Non odio possimus quo quis praesentium, quam reiciendis sed eligendi! Vitae nihil voluptatum atque expedita nesciunt. Voluptatibus explicabo asperiores nisi ab? Rerum.
          </p>

          <p className="AgendaDeatilsHeadings">
            5.      Lorem ipsum dolor sit amet consectetur adipisicing elit. Non odio possimus quo quis praesentium, quam reiciendis sed eligendi! Vitae nihil voluptatum atque expedita nesciunt. Voluptatibus explicabo asperiores nisi ab? Rerum.
          </p>
          <p className="AgendaDetailPoints">
            {" "}
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Non odio possimus quo quis praesentium, quam reiciendis sed eligendi! Vitae nihil voluptatum atque expedita nesciunt. Voluptatibus explicabo asperiores nisi ab? Rerum.
          </p>

          <p className="AgendaDeatilsHeadings">6. Conclusion</p>
          <p className="AgendaDetailPoints">
            {" "}
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Non odio possimus quo quis praesentium, quam reiciendis sed eligendi! Vitae nihil voluptatum atque expedita nesciunt. Voluptatibus explicabo asperiores nisi ab? Rerum.
          </p>
        </div>
      </div>
    </>,
    document.querySelector(".MyModalDiv")
  );
};

export default Agenda;
