import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { AiOutlineMenu} from "react-icons/ai";
import { useState } from "react";
const Navbar = () => {

  const [clickMenu, setClickMenu] = useState(false);
  return (
    <div>
    <div className="navbarComponentContainer">
      <div className="navItem navItemLogo"> <Link to='/' className="becpmeASpeakerRoute"><img src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/images%2FLogoNavbar%20(1).png?alt=media&token=37384f28-d7f9-4ff8-b1ec-aecf642d6c37" alt="logo for navbar" className="footerLogoImgSizeNavbar"/></Link></div>
     
      <div className="navItem navItemWebinars">
      <button className="menuClickNavbar" onClick={()=>setClickMenu(!clickMenu)}><AiOutlineMenu/></button>
  
      </div>
      
   
    </div>
    {clickMenu && (
      <div className="hamburgerMenu">
      <div className="hamburgerWebinars">Webinars</div>
      <div className="hamburgerWebinars">LMS demo</div>
      <Link to="/contactUs" className="hamburgerWebinarsLink"><div className="hamburgerWebinars">Become a speaker</div></Link>
    

      <div className="hamburgerSignUpButton"><Link to='/signup'><button className="signUpButtonInNavabarHamburgerMenu">Login</button></Link></div>
      
      </div>
    )}
    </div>
  );
};

export default Navbar;
