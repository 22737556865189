import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import "./SpeakerModal.css";
const SpeakerModal = ({ closeSpeakerModal }) => {
  useEffect(() => {
    document.body.style.overflowY = "hidden";

    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, []);

  return createPortal(
    <>
      <div className="modalWrapper" onClick={closeSpeakerModal}></div>
      <div className="SpeakerModalContainer">
     <div className="ModalSpeakerImgAndName">
     <div className="ModalSpeakerNameAndCertification">
    <h1 className="ModalSpeakerNameAndCertificationName">Caitlin Wham </h1>
    <h3 className="ModalSpeakerNameAndCertificationCertification">| Inpatient & Outpatient Coding Auditor and Educator | Medical Coding Expert | Freelance Blogger | Certified Coding Specialist | AHIMA-Approved ICD-10-CM/PCS Trainer</h3>
     </div>
     <div className="ModalSpeakerNameAndCertificationSpeakerImage">
  
     <img src="https://firebasestorage.googleapis.com/v0/b/promptifyer-6ab34.appspot.com/o/Speaker%20Profile%2Fcaitlin%20wham%2FCaitlinWhamSpeakerImage.png?alt=media&token=fbedb5a6-a6bf-41e7-a3fb-bf952f580c4f" className="ModalSpeakerNameAndCertificationSpeakerImageImg" alt=" speaker image"/>
     </div>
     </div>
     <div className="ModalSpeakerInfo">
     <p className="ModalSpeakerInfoP">
     Accomplished, certified professional with 15 years of experience in Health Information Management. 10 years of experience coding with ICD and CPT-4 coding systems in a acute care settings for large healthcare systems. 7 years of experience in coding auditing, compliance and education.
     <br/>
     <br/>

     Comprehensive knowledge of Medicare/Medicaid and third-party coding and reporting requirements with the ability to interpret and understand laws and regulations at a Federal and State level.
     <br/>
     <br/>
     
     Proven ability to both create and implement education projects of various size and scope.
     <br/>
     <br/>
     
     Particularly successful in taking unstructured or complex new projects and guiding them from start to finish.
     <br/>
     <br/>
     
     Consistently commended for leadership, initiative and the ability to break down technical information into easy to understand education, helping to quickly train and engage coding professionals.
     </p>
     </div>
      </div>
    </>,
    document.querySelector(".MyModalDiv")
  );
};

export default SpeakerModal;
