import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import "./Webinar.css";
import { InputText } from "primereact/inputtext";
import Footer from "../components/Footer";
import {
  FcQuestions,
  FcVoicePresentation,
  FcKey,
  FcReading,
  FcRefresh,
  FcTodoList,
  FcFinePrint,
  FcCamcorderPro,
} from "react-icons/fc";
import { SiStarbucks } from "react-icons/si";
import { BiCoffeeTogo } from "react-icons/bi";
import { BsBookmarkHeart } from "react-icons/bs";
import { MdOutlineLiveTv, MdOutlineTimelapse } from "react-icons/md";
import { FiUserCheck } from "react-icons/fi";
import { BsPatchQuestion } from "react-icons/bs";
import { RxPencil2 } from "react-icons/rx";
import { GiArchiveRegister } from "react-icons/gi";
import { GiCaduceus } from "react-icons/gi";
import { BsQuestionSquare } from "react-icons/bs";
import ScrollAnimation from "react-animate-on-scroll";
import { AiOutlineDollar } from "react-icons/ai";
import Agenda from "../components/Agenda";
import SpeakerModal from "../components/SpeakerModal";
import SusanModal from "../components/SusanModal";
import SusanAgenda from "../components/SusanAgends";
import Navbar2 from "../components/Navbar2";
const IcdTenWebinar = () => {
  const [inputMailList, setInputMailList] = useState("");
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [showAgenda, setShowAgenda] = useState(false);
  const [showSpeakerModal, setShowSpeakerModal] = useState(false);
  const [susanModal, setSusanModal] = useState(false);
  const [susanAgenda, setSusanAgenda] = useState(false);

  const handleImageChange = (index) => {
    setSelectedImageIndex(index);
  };

  const closeModal = () => {
    setShowAgenda(false);
  };
  const closeSusanAgendaModal = () => {
    setSusanAgenda(false);
  };
  const closeSpeakerModal = () => {
    setShowSpeakerModal(false);
  };
  const closeSusanModal = () => {
    setSusanModal(false);
  };

  const imageUrls = [
    "https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/SDOH%2FDreamShaper_v7_Images_for_Social_determinants_of_health_webina_3.jpg?alt=media&token=f820b763-b6e2-4284-a475-1dbd1cfd156b",
    "https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/SDOH%2FDreamShaper_v7_Images_for_Social_determinants_of_health_webina_5.jpg?alt=media&token=78198f64-93b2-4f27-816e-fdf406ae2121",
    "https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/SDOH%2FDreamShaper_v7_Images_for_Social_determinates_of_health_webina_0.jpg?alt=media&token=dd3e4ac1-cfc4-45d0-a978-face41f08c7b",
    "https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/SDOH%2FDreamShaper_v7_Images_for_Social_determinates_of_health_webina_3.jpg?alt=media&token=71e4fcab-bf06-445a-9e2e-c1793944d76a",
    "https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/SDOH%2FDreamShaper_v7_Images_for_Social_determinants_of_health_webina_1.jpg?alt=media&token=77a02815-a5e9-4634-854b-f7477beed3d5",
    "https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/SDOH%2FDreamShaper_v7_Images_for_Social_determinants_of_health_webina_3%20(1).jpg?alt=media&token=9eca379f-a5a6-438d-ac24-26500be4b1cd",
  ];

  return (
    <div className="WebinarContainer">
      {showAgenda && <Agenda closeModal={closeModal} />}
      {susanAgenda && (
        <SusanAgenda closeSusanAgendaModal={closeSusanAgendaModal} />
      )}
      {showSpeakerModal && (
        <SpeakerModal closeSpeakerModal={closeSpeakerModal} />
      )}
      {susanModal && <SusanModal closeSusanModal={closeSusanModal} />}
      <div className="navbarContainer">
        <Navbar2 />
      </div>
      <div className="firstFoldWebinarContainer">
        <div className="firstFoldWebinarContainerLeftAndRight">
          <div className="WebinarFullDetailsColumnLeft">
            <div className="firstFoldInfoContainer">
              <p className="firstFoldInfoContainerTagline">
                Webinar: 2024 ICD-10 CM Changes and Updates
              </p>
              <p className="firstFoldInfoContainerfirstpara">
                CDC unveils ICD-10-CM Codes for the upcoming FY 2024 with 395
                New Additions, deletion of 25 existing codes and revises 22
                codes. Majority of the new codes were proposed for greater
                specificity and to increase tracking and progress.
                <br />
                <br />
                The updates go into effect from October 1, 2023, starting from
                visits or discharges occurring on or after October 1 , 2023.
              </p>
              <div className="firstFoldInfoContainerButtonDivICD10">
              <div className="firstFoldInfoContainerButtonDivICD10ButtonAndAAPC">
                <button className="fifthFoldInfoContainerButton1ICD10">
                  <a
                    target="_blank"
                    href="https://buy.stripe.com/8wMg2yfI1cnh5mU6oq"
                    className="fifthFoldInfoContainerButton1Link3"
                  >
                    Register Now
                  </a>
                </button>
            
                {/*<button className="firstFoldInfoContainerButton2">
 Download Agenda
</button>*/}  
</div>
<p className="firstFoldInfoContainerfirstLastpara">
| 15<sup>th</sup> September, 2023| 3:00pm ET |
</p>

              </div>
             
              <div
                className="WebinarBuyingInfoContainerDetailsSpeakerICD10
          "
              >
                <div className=" WebinarBuyingInfoContainerDetailsSpeakerFirstFold">
                  <div className="speakerContainerDetails">
                    <div className="speakerContainerDetailsHeadingDiv">
                      <h1 className="speakerContainerDetailsHeading">
                        {" "}
                        Siji Susan Joy
                        <br />
                        <p className="speakerContainerDetailsHeadingCertification">
                          CPC,CCS,AHIMA Apprvd Trainer
                        </p>
                      </h1>
                      <p
                        className="speakerContainerDetailsHeadingreadMore"
                        onClick={() => setSusanModal(true)}
                      >
                        Read More
                      </p>
                    </div>
                  </div>
                  <div className="speakerImageContainer">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/ICD%2010%2FuserImage-removebg-preview.png?alt=media&token=751d6f3e-33d6-4c15-b087-1848a3a2e3e8"
                      className="speakerImage"
                      alt=" speaker image"
                    />
                  </div>
                </div>
              </div>
           
             
              <div className="StripeQRCodeContainer">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/ICD%2010%2F%2499ICD10QRCode.png?alt=media&token=a2bfe2bb-27b3-4671-9681-e42a71d2b1e7"
                  alt="qr code"
                  className="StripeQRCodeContainerImg"
                />
              </div>
              <a href="/medical-coding-education/">
              <img
                src="https://static.aapc.com/aapc/images/aapc-ceu-approved-200x74.gif"
                alt="AAPC Medical Coding CEU Approved Vendor"
                width="148"
                height="52"
                border="0"
                style={{
                  borderRadius: "5px",
                marginLeft:"50px",
                position:"absolute",
                bottom:"157px",
                right:"125px"
                }}
              />
            </a>
            </div>
          </div>
          <div className="WebinarBuyingInfoContainer">
            <div className="WebinarBuyingInfoContainerWrapper">
              <div className="WebinarBuyingInfoContainerDetailsICD10Coffee">

             <div className="WebinarBuyingInfoContainerDetailsICD10CoffeeIconContainer">

             <div className="WebinarBuyingInfoContainerDetailsICD10CoffeeIconContainerFreeImageDiv">
                  <img src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/ICD%2010%2Ffreegif-removebg-preview.png?alt=media&token=e0eb5fc3-0919-4372-8bb2-11dd3e7febae"  className="WebinarBuyingInfoContainerDetailsICD10CoffeeIconContainerFreeImage"/>
                  </div>
                  <SiStarbucks style={{fontSize:"120px",  color:"#036635",width:"35%"}}/>
             </div>
             <p className="WebinarBuyingInfoContainerDetailsICD10CoffeeH1">Free <span style={{color:"#036635"}}>Starbucks Coffee Voucher </span>For Every webinar Attendee</p>
          
              </div>
              <div className="WebinarBuyingInfoContainerDetailsICD10Coffee2">

              <p className="WebinarBuyingInfoContainerDetailsICD10Coffeebelowp">Sponserd By</p>
              <a href="https://www.kickstarter.com/projects/enprompt360/enprompt-360?utm_source=website&utm_medium=icdweb&utm_id=icd10" style={{width:"100%",height:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}>
          <img src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/ICD%2010%2FLogoICD10Enprompt360-removebg-preview.png?alt=media&token=02a80c57-4b3b-403a-af11-d0fcb29a88e3" style={{width:"70%",height:"86%"}}/>
               </a>
               </div>
        
              <div className="WebinarBuyingInfoContainerDetailsICD10">
                <div className="WebinarBuyingInfoContainerDetailsPart2Div">
                  <div className="WebinarBuyingInfoContainerDetailsPart2DivRowICD10">
                  <p className="WebinarBuyingInfoContainerDetaiclsBuyButtonPricePICD10">
                  $ 99
                </p>
                  </div>
                  <div className="WebinarBuyingInfoContainerDetailsPart2DivRowICD10">
                    <p className="WebinarBuyingInfoContainerDetailsPart2DivRowP">
                      Live
                    </p>
                  </div>
                  <div className="WebinarBuyingInfoContainerDetailsPart2DivRowICD10">
                    <p className="WebinarBuyingInfoContainerDetailsPart2DivRowP">
                      12 Months Recording
                    </p>
                  </div>
                  <div className="WebinarBuyingInfoContainerDetailsPart2DivRowICD10">
                    <p className="WebinarBuyingInfoContainerDetailsPart2DivRowP">
                      1 AAPC CEU
                    </p>
                  </div>
                  <div className="WebinarBuyingInfoContainerDetailsBuyButtonICD10Div">
                    {/*  <p className="WebinarBuyingInfoContainerDetaiclsBuyButtonP">
              <a target="_blank" href="https://buy.stripe.com/7sIcQm53n8714iQ5kl" className="fifthFoldInfoContainerButton1Link2" >Book Now</a>
              </p>
              <p className="WebinarBuyingInfoContainerDetaiclsBuyButtonPriceP">
                $ 99
</p>*/}
                    <button className="WebinarBuyingInfoContainerDetailsBuyButtonICD10Button">
                    <a
                    target="_blank"
                    href="https://buy.stripe.com/8wMg2yfI1cnh5mU6oq"
                    className="fifthFoldInfoContainerButton1LinkBook"
                  >
                      Book Now
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="secondFoldWebinarContainer">
        <div className="SecondFoldBothInfoAndInputConatiner">
          <div className="secondFoldWebinarContainerInfo">
            <p className="secondFoldWebinarContainerInfoTagline">
              Join our mailinglist now for{" "}
              <span className="secondFoldWebinarContainerInfoTaglineImportant">
                Updates, Agendas, Reminders
              </span>
            </p>
          </div>
          <div className="secondFoldWebinarContainerInputTextDiv">
            <InputText
              value={inputMailList}
              onChange={(e) => setInputMailList(e.target.value)}
              className="secondFoldWebinarContainerInputText"
              placeholder="john@riverdale.com"
            />
            <button className="firstFoldInfoContainerButton2">Join now!</button>
          </div>
        </div>
      </div>
      <div className="thirdFoldWebinarContainer">
        <div className="sessionHighlights">
          <div className="sessionHighlightsDivHeading">
            <h1 className="thirdFoldWebinarContainerCenterdivAboveP">
              Session Highlights
            </h1>
          </div>
          <div className="sessionHighlightsInfo">
            {/*  <div className="left">
                    <img
                      src={imageUrls[selectedImageIndex]}
                      alt="Session Highlight"
                      className="highlightImage"
                    />
</div>*/}
            <div className="right">
              <ul className="rowICD">
                <li style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                  Notable proposed ICD-10-CM code additions related to
                  <br />
                  - Parkinson’s disease <br />
                  - Sickle Cell Anemia <br />
                  - Disease of the Eye and Adnexa
                  <br />
                  - Osteoporosis
                  <br />
                  - Mammographic findings
                  <br />
                  - Insulin resistance
                  <br />- External cause codes that specify foreign body
                  entering through a natural orifice, to - capture accidents and
                  injuries
                </li>

                <li>
                  New codes added and revisions in the congenital malformations,
                  deformations and chromosomal abnormalities (Q00-Q99) chapter
                </li>
                <li>
                  Guidelines update : coding of pre-op Covid-19 screening,
                  comas, Acute Myocardial Infarction (AMI)
                </li>
                <li>Changes in the tabular addenda</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="FifthFdoldWebinarContainer">
        <div className="fifthFoldInfoContainerButtonDiv">
          <button className="fifthFoldInfoContainerButton">
            {" "}
            <a
              target="_blank"
              href="https://buy.stripe.com/8wMg2yfI1cnh5mU6oq"
              className="fifthFoldInfoContainerButton1Link3"
            >
              Register Now
            </a>
          </button>
          {/* <button className="firstFoldInfoContainerButton2">
     Download Agenda
</button>*/}
        </div>
      </div>

      <div className="FourthFoldWebinarContainer">
        <div className="FourthFoldWebinarContainerHeadingDiv">
          <h1 className="FourthFoldWebinarContainerHeading">
            <p className="FourthFoldWebinarContainerHeadingFAQ"> </p>
            <ScrollAnimation
              duration={5}
              animateIn="hinge"
              initiallyVisible={true}
            >
              <p className="FourthFoldWebinarContainerHeadingFAQ">
                Frequently Asked Questions
              </p>
            </ScrollAnimation>
            <span className="FourthFoldWebinarContainerHeadingTagline">
              Quick answers to questions you may have.Cant't find what you are
              looking for?{" "}
              <a className="FourthFoldWebinarContainerHeadingTaglineLink">
                Get in touch.
              </a>
            </span>
          </h1>
        </div>
        <div className="faqDIV">
          <div className="faqDIVLeft">
            <div className=" faqDIVLeftRow">
              <div className="faqDIVLeftRowIconDiv">
                <MdOutlineLiveTv className="faqDIVLeftRowIcon" />
              </div>
              <div className="faqDIVLeftRowInfoDiv">
                <div className="faqDIVLeftRowInfoHeadingDiv">
                  <h1 className="faqDIVLeftRowInfoHeading">
                    {" "}
                    What if I can not attend live?
                  </h1>
                </div>
                <div className="faqDIVLeftRowInfoDivInfoDiv">
                  <p className="faqDIVLeftRowInfoDivInfoP">
                    You’ll have access to recording(On demand) and transcript
                    for 12 months. You can still ask questions to the Speaker
                    via email.
                  </p>
                </div>
              </div>
            </div>

            <div className=" faqDIVLeftRow">
              <div className="faqDIVLeftRowIconDiv">
                <MdOutlineTimelapse className="faqDIVLeftRowIcon" />
              </div>
              <div className="faqDIVLeftRowInfoDiv">
                <div className="faqDIVLeftRowInfoHeadingDiv">
                  <h1 className="faqDIVLeftRowInfoHeading">
                    {" "}
                    How long is the webinar?
                  </h1>
                </div>
                <div className="faqDIVLeftRowInfoDivInfoDiv">
                  <p className="faqDIVLeftRowInfoDivInfoP">
                    {" "}
                    The webinar is about 60 minutes long, including Q&A
                    sessions.
                  </p>
                </div>
              </div>
            </div>
            <div className=" faqDIVLeftRow">
              <div className="faqDIVLeftRowIconDiv">
                <AiOutlineDollar className="faqDIVLeftRowIcon" />
              </div>
              <div className="faqDIVLeftRowInfoDiv">
                <div className="faqDIVLeftRowInfoHeadingDiv">
                  <h1 className="faqDIVLeftRowInfoHeading">
                    How much does the webinar cost?
                  </h1>
                </div>
                <div className="faqDIVLeftRowInfoDivInfoDiv">
                  <p className="faqDIVLeftRowInfoDivInfoP">
                    {" "}
                    The registration fee for the webinar is [amount].
                  </p>
                </div>
              </div>
            </div>
            <div className=" faqDIVLeftRow">
              <div className="faqDIVLeftRowIconDiv">
                <RxPencil2 className="faqDIVLeftRowIcon" />
              </div>
              <div className="faqDIVLeftRowInfoDiv">
                <div className="faqDIVLeftRowInfoHeadingDiv">
                  <h1 className="faqDIVLeftRowInfoHeading">
                    {" "}
                    How do I register for the webinar?
                  </h1>
                </div>
                <div className="faqDIVLeftRowInfoDivInfoDiv">
                  <p className="faqDIVLeftRowInfoDivInfoP">
                    {" "}
                    To register for the webinar, click on the button below. You
                    will receive a confirmation email with the webinar link and
                    instructions a couple days before the live webinar.
                  </p>
                </div>
              </div>
            </div>
            <div className=" faqDIVLeftRow">
              <div className="faqDIVLeftRowIconDiv">
                <GiCaduceus className="faqDIVLeftRowIcon" />
              </div>
              <div className="faqDIVLeftRowInfoDiv">
                <div className="faqDIVLeftRowInfoHeadingDiv">
                  <h1 className="faqDIVLeftRowInfoHeading">
                    {" "}
                    How do I earn CEU credit from this webinar?
                  </h1>
                </div>
                <div className="faqDIVLeftRowInfoDivInfoDiv">
                  <p className="faqDIVLeftRowInfoDivInfoP">
                    {" "}
                    To earn CEU credit from this webinar, you need to attend the
                    live session or watch the recording. You will also need to
                    complete a quiz. You will receive a certificate of
                    completion with 1 CEU credit from AAPC for this Webinar.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="faqDIVRight"></div>
        </div>
      </div>

      <div className="footerContainerWebinarContainer">
        <Footer />
      </div>
    </div>
  );
};

export default IcdTenWebinar;
