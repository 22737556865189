import Navbar from "../components/Navbar2";
import "./ICD102.css";

import { MdLiveTv } from "react-icons/md";
import { BiVideoRecording } from "react-icons/bi";
import { Link } from "react-router-dom";
import "./SDOH.css";
import { InputText } from "primereact/inputtext";
import ReCAPTCHA from "react-google-recaptcha";
import Footer from "../components/Footer";
import { useState, useRef } from "react";
import { IoPricetagsOutline } from "react-icons/io";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import "./Webinar.css";
import { BsFillCalendar2CheckFill,BsClock ,BsCalendarWeek} from "react-icons/bs";
import { BiDollar } from "react-icons/bi";
import Navbar2 from "../components/Navbar2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FcQuestions,
  FcVoicePresentation,
  FcKey,
  FcReading,
  FcRefresh,
  FcTodoList,
  FcFinePrint,
  FcCamcorderPro,
} from "react-icons/fc";
import { SiStarbucks } from "react-icons/si";

import { BsBookmarkHeart } from "react-icons/bs";
import { MdOutlineLiveTv, MdOutlineTimelapse } from "react-icons/md";
import { FiUserCheck } from "react-icons/fi";
import { BsPatchQuestion } from "react-icons/bs";
import { RxPencil2 } from "react-icons/rx";
import { GiArchiveRegister } from "react-icons/gi";
import { GiCaduceus } from "react-icons/gi";
import { BsQuestionSquare } from "react-icons/bs";
import ScrollAnimation from "react-animate-on-scroll";
import { AiOutlineDollar } from "react-icons/ai";
import Agenda from "../components/Agenda";
import SpeakerModal from "../components/SpeakerModal";
import JoinModal from "../components/JoinModal";
import { collection, addDoc } from "firebase/firestore";
import { fs } from "../firebase";
import NotifyMeModal from '../components/ICD10RegisterModal'
import FreeICD10RegisterModal from '../components/FreeICD10RegisterModal'

import emailjs from "@emailjs/browser";

const ICD102 = () => {
  const [clickMenu, setClickMenu] = useState(false);
    const [inputMailList, setInputMailList] = useState("");
    const [inputJoinList, setInputJoinList] = useState("");
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [showAgenda, setShowAgenda] = useState(false);
    const [showSpeakerModal, setShowSpeakerModal] = useState(false);
    const [showJoinModal, setShowJoinModal] = useState(false);
    const [emailTop, setEmailTop] = useState("");
    const [successMsg, setSuccessmsg] = useState("");
    const [error, setError] = useState("");
    const [to_name, setto_name] = useState("")
    const [lastName, setlastName] = useState("")
    const [to_email, setto_email] = useState("")
    const [companyName, setComapnyName] = useState("")
    const [jobTitle, setjobTitle] = useState("")
    const [addToEmailList, setAddToEmailList] = useState(false);


    const form = useRef();
    const notify = () => {
      toast.success("You are successfully added to our emailing list!", {
        position: toast.POSITION.TOP_CENTER,
      });
    };
    const addDeatailsToEmailList = async (data) => {
      const { to_name, lastName, to_email, companyName, jobTitle } = data;
      // console.log(fname,lname,email,certification,phone,time,date,topicOfInterest)

      notify();

      const docRef = await addDoc(collection(fs, "SDOHRegistriesEmailList"), {
        to_name,lastName,to_email,companyName,jobTitle,    addToEmailList,
      })
        .then(() => {
          setSuccessmsg("Your are successfully added to our emailing list!");
          setEmailTop("");
          setError("");
          setto_name('')
          setlastName('')
          setto_email('')
          setComapnyName('')
          setjobTitle('')
          setAddToEmailList(false);
          setTimeout(() => {
            setSuccessmsg("");
          }, 3000);
        })
        .catch((error) => setError(error.message));
  
    };
    const handleImageChange = (index) => {
      setSelectedImageIndex(index);
    };

    const closeModal = () => {
      setShowAgenda(false);
    };
    const closeJoinModal = () => {
      setShowJoinModal(false);
      setInputJoinList("");
    };
    const closeSpeakerModal = () => {
      setShowSpeakerModal(false);
    };

    const [susanModal, setSusanModal] = useState(false)
    const closeSusanModal =()=>{
      setSusanModal(false);
    }
  
    
    const sendEmail = (formData) => {
      emailjs
        .sendForm(
          "service_s5e9vpe",
          "template_86gkunk",
          form.current,
          "Xt5MhTFtzdO3DyOFK"
        )
        .then(
          (result) => {
            console.log(result.text, "Email sent successfully!");
          },
          (error) => {
            console.log(error.text);
          }
        );
    };

    // Define a state variable to track whether the captcha is checked
const [captchaChecked, setCaptchaChecked] = useState(false);

// ...

const onChange = (value) => {
  // Assuming that `value` is the value returned by ReCAPTCHA
  if (value) {
    // If the captcha is checked, clear the error message
    setCaptchaChecked(true);
  }
};

    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();



  return (
    <div>
    {susanModal && <NotifyMeModal closeSusanModal={closeSusanModal} />}
    {showSpeakerModal && (
      <SpeakerModal closeSpeakerModal={closeSpeakerModal} />
    )}
      <div className="offerAboveNavbar">
        <p className="offerAboveNavbarP">
          {" "}
          Free Starbucks Coffee Voucher for all attendees
        </p>
      </div>
      <Navbar />
      <div className="icd102FirstFoldContainer">
        <div className="icd102FirstFoldContainerLeft">
          <div className="icd102FirstFoldContainerLeftTopImageContainer">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/SDOH%2FsdohHero.jpeg?alt=media&token=eaabc564-12a5-40e6-8daa-a43ca7797028"
              className="icd102FirstFoldContainerLeftTopImage"
            />
            <div className="icd102FirstFoldContainerLeftTopRightSection">
           
              <div className="icd102FirstFoldContainerLeftTopRightSectioCoffeeContainer">
                <p className="aboveFree">Date</p>
                <h1 className="Free">6th October 2023, 3:00 pm ET</h1>
              </div>
              <div className="icd102FirstFoldContainerLeftTopRightSectioCoffeeContainer">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/CDI%20Nurses%2FMarsha%20Image.jpg?alt=media&token=ebad9784-1974-448e-b96a-c37f41e3b4f0"
                  className="icd102FirstFoldContainerLeftTopRightSectioCoffeeContainerImage1"
                />
                <h1 className="Free">
                  By Caitlin Wham<br></br>CCS
                </h1>
                <p className="textBelowSpeaker">Accomplished, certified professional with 15 years of experience in Health Information Management. 10 years of experience coding with ICD and CPT-4 coding systems in a acute care settings for large healthcare systems. 7 years of experience in coding auditing, compliance and education.</p>
                <button className="speakerReadMoreInfoButton" onClick={() =>   setShowSpeakerModal(true)}>Read More</button>
              </div>
            </div>
          </div>
          <div className="horizontalLine"></div>
          <div className="horizontalLine"></div>

          <div className="DiveAboveSessionHighlights">
          <p className="DiveAboveSessionHighlightsP">
            <h1 className="thirdFoldWebinarContainerCenterdivAbovePP">
            Key Coding Principles for CDI Nurses
            </h1>
            Clinicians and Coders view the chart from a different lens.  While clinicians have a deep understanding of the patient’s disease process and treatment, the coder is focused on what is explicitly documented and how that will translate into a valid diagnosis or procedure code. To be a successful CDI Nurse you must successfully make the mindset shift to understand coding conventions while leveraging your clinical expertise.
       
            Growing need for CDI Nurses to perform clinical validation with increasing denials.

            Opportunity for nursing to move away from the physical demands of working at the bedside.

            A great CDI Nurse is always appreciated by coding staff.  

          
          </p>
        </div>

        <div className="sessionHighlights">
        <div className="sessionHighlightsDivHeading">
          <h1 className="thirdFoldWebinarContainerCenterdivAboveP">
            Session Highlights
          </h1>
        </div>
        <div className="sessionHighlightsInfo">
         {/*  <div className="left">
                  <img
                    src={imageUrls[selectedImageIndex]}
                    alt="Session Highlight"
                    className="highlightImage"
                  />
</div>*/}
          <div className="right">
            <ul className="rowICD">
            
              <li style={{paddingTop:"5px",paddingBottom:"5px"}} >
              What you need to know to make a successful transition from Nursing to Coding as a CDI
        
              
              </li>
           
        
           
          
            <li  >
            CDI and Coding are complimentary not competitive. Understanding your new role. 
            </li>
            <li >
            Make the mindset shift.  What’s missing can’t be assumed.  Codes are based on documentation. 
         
            </li>
            <li >
            Leverage your expertise to ensure assigned codes are clinically valid. 
     
            </li>
            <li >
            Understand Principal Diagnosis Selection.  POA does not always equal PDX.
     
            </li>
            <li >
            Too many codes and in the wrong order. Understand sequencing guidelines and combination codes.
     
            </li>
            <li >
            Protect your organization. Always write compliant queries.
     
            </li>
            <li >
            Ethical considerations in CDI. 
     
            </li>
          
            </ul>
          </div>
          
        </div>
      </div>

      <div className="FourthFoldWebinarContainer">
      <div className="FourthFoldWebinarContainerHeadingDiv">
        <h1 className="FourthFoldWebinarContainerHeading">
          <p className="FourthFoldWebinarContainerHeadingFAQ"> </p>
          <ScrollAnimation
            duration={5}
            animateIn="hinge"
            initiallyVisible={true}
          >
            <p className="FourthFoldWebinarContainerHeadingFAQ">
              Frequently Asked Questions
            </p>
          </ScrollAnimation>
          <span className="FourthFoldWebinarContainerHeadingTagline">
            Quick answers to questions you may have.Cant't find what you are
            looking for?{" "}
            <a className="FourthFoldWebinarContainerHeadingTaglineLink">
              Get in touch.
            </a>
          </span>
        </h1>
      </div>
      <div className="faqDIV">
        <div className="faqDIVLeft">
          <div className=" faqDIVLeftRow">
            <div className="faqDIVLeftRowIconDiv">
              <MdOutlineLiveTv className="faqDIVLeftRowIcon" />
            </div>
            <div className="faqDIVLeftRowInfoDiv">
              <div className="faqDIVLeftRowInfoHeadingDiv">
                <h1 className="faqDIVLeftRowInfoHeading">
                  {" "}
                  What if I can not attend live?
                </h1>
              </div>
              <div className="faqDIVLeftRowInfoDivInfoDiv">
                <p className="faqDIVLeftRowInfoDivInfoP">
                  You’ll have access to recording(On demand) and transcript
                  for 12 months. You can still ask questions to the Speaker
                  via email.
                </p>
              </div>
            </div>
          </div>
        
    
          <div className=" faqDIVLeftRow">
            <div className="faqDIVLeftRowIconDiv">
              <MdOutlineTimelapse className="faqDIVLeftRowIcon" />
            </div>
            <div className="faqDIVLeftRowInfoDiv">
              <div className="faqDIVLeftRowInfoHeadingDiv">
                <h1 className="faqDIVLeftRowInfoHeading">
                  {" "}
                  How long is the webinar?
                </h1>
              </div>
              <div className="faqDIVLeftRowInfoDivInfoDiv">
                <p className="faqDIVLeftRowInfoDivInfoP">
                  {" "}
                  The webinar is about 60 minutes long, including Q&A
                  sessions.
                </p>
              </div>
            </div>
          </div>
          <div className=" faqDIVLeftRow">
            <div className="faqDIVLeftRowIconDiv">
              <AiOutlineDollar className="faqDIVLeftRowIcon" />
            </div>
            <div className="faqDIVLeftRowInfoDiv">
              <div className="faqDIVLeftRowInfoHeadingDiv">
                <h1 className="faqDIVLeftRowInfoHeading">
                  How much does the webinar cost?
                </h1>
              </div>
              <div className="faqDIVLeftRowInfoDivInfoDiv">
                <p className="faqDIVLeftRowInfoDivInfoP">
                  {" "}
                  The registration fee for the webinar is $99.
                </p>
              </div>
            </div>
          </div>
          <div className=" faqDIVLeftRow">
            <div className="faqDIVLeftRowIconDiv">
              <RxPencil2 className="faqDIVLeftRowIcon" />
            </div>
            <div className="faqDIVLeftRowInfoDiv">
              <div className="faqDIVLeftRowInfoHeadingDiv">
                <h1 className="faqDIVLeftRowInfoHeading">
                  {" "}
                  How do I register for the webinar?
                </h1>
              </div>
              <div className="faqDIVLeftRowInfoDivInfoDiv">
                <p className="faqDIVLeftRowInfoDivInfoP">
                  {" "}
                  To register for the webinar, click on the button above. You
                  will receive a confirmation email with the webinar link and
                  instructions a couple days before the live webinar.
                </p>
              </div>
            </div>
          </div>
          <div className=" faqDIVLeftRow">
            <div className="faqDIVLeftRowIconDiv">
              <GiCaduceus className="faqDIVLeftRowIcon" />
            </div>
            <div className="faqDIVLeftRowInfoDiv">
              <div className="faqDIVLeftRowInfoHeadingDiv">
                <h1 className="faqDIVLeftRowInfoHeading">
                  {" "}
                  How do I earn CEU credit from this webinar?
                </h1>
              </div>
              <div className="faqDIVLeftRowInfoDivInfoDiv">
                <p className="faqDIVLeftRowInfoDivInfoP">
                  {" "}
                  To earn CEU credit from this webinar, you need to attend the
                  live session or watch the recording. You will also need to
                  complete a quiz. You will receive a certificate of
                  completion with 1 CEU credit from AAPC for this Webinar.
                </p>
              </div>
            </div>
          </div>

          
        </div>
        <div className="faqDIVRight"></div>
      </div>
</div>
        </div>
        <div className="icd102FirstFoldContainerRight">
        <div className="icd102FirstFoldContainerLeftTopRightSectioCoffeeContainer1">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/SDOH%2FThanksCaffeineBoost.png?alt=media&token=26ff7827-1384-4370-8c84-509d7576f0b2"
          className="icd102FirstFoldContainerLeftTopRightSectioCoffeeContainerImage"
        />
        <h1 className="Free1">Free Starbucks<br></br> Coffe Voucher</h1>
      </div>
        <div className="icd102FirstFoldContainerRightButtonDiv"     onClick={() => setSusanModal(true)}>
        <span style={{paddingLeft:"10px"}}>Book Now</span>
        <span style={{paddingRight:"10px"}}>$99</span>

        </div>
        <div className="icd102FirstFoldContainerRightWebinarInfo">
        <span style={{paddingLeft:"10px"}}>Duration:</span>
        <span style={{paddingRight:"10px"}}>60-70 mins</span>
        
        </div>
        <div className="horizontalLineWebinarInfo"></div>
        <div className="icd102FirstFoldContainerRightWebinarInfo">
        <span style={{paddingLeft:"10px"}}>Live</span>
        <span style={{paddingRight:"10px"}}> <MdLiveTv style={{ fontSize: "18px",color:"#6664DA" }} /></span>
        
        </div>
        <div className="horizontalLineWebinarInfo"></div>
        <div className="icd102FirstFoldContainerRightWebinarInfo">
        <span style={{paddingLeft:"10px"}}>12 Months Recording</span>
        <span style={{paddingRight:"10px"}}>   <BiVideoRecording style={{ fontSize: "18px",color:"#6664DA" }} /></span>
        
        </div>
        <div className="horizontalLineWebinarInfo"></div>
        <div className="icd102FirstFoldContainerRightWebinarInfo1">
        <img src="https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/SDOH%2Faapc-ceu-approved-200x74%20(1).gif?alt=media&token=75a7ea03-bccf-4385-bb16-6d61b8e68f41" alt="AAPCE CEU Approved" className="newAapcImageWebinarInfo"/>
        
        </div>
        <div className="horizontalLineWebinarInfo">
        </div>
        <h1 className="upcomingWebinarsRight">Upcoming Webinars</h1>
     

        <div className="icd102FirstFoldContainerRightWebinarInfo2">
        <span style={{paddingLeft:"10px",textAlign:"left",marginTop:"5px",color:"#6664DA"}}>  Webinar: 2024 ICD-10 CM Changes and Updates</span>
        <div className="priceOfUpcomingWebinar">
        <span style={{textAlign:"center",marginTop:"5px",color:"white",backgroundColor:"#FF5757",width:"120px",height:"24px",marginBottom:"10px",borderRadius:"5px"}}>Learn More</span>

        </div>
        <span style={{paddingLeft:"10px",textAlign:"left",marginTop:"5px",color:"black",fontSize:"17px"}}>By Siji Susan Joy CCS,CPC</span>
        </div>
        <div className="horizontalLineWebinarInfo"></div>
        <div className="icd102FirstFoldContainerRightWebinarInfo2">
        <span style={{paddingLeft:"10px",textAlign:"left",marginTop:"5px",color:"#6664DA"}}> Key Coding Principles for CDI Nurses</span>
        <div className="priceOfUpcomingWebinar">
        <span style={{textAlign:"center",marginTop:"5px",color:"white",backgroundColor:"#FF5757",width:"120px",height:"24px",marginBottom:"10px",borderRadius:"5px"}}>Learn More</span>

        </div>
        <span style={{paddingLeft:"10px",textAlign:"left",marginTop:"5px",color:"black",fontSize:"17px"}}>By Marsha Page, CCS-P</span>
        </div>
        <div className="horizontalLineWebinarInfo"></div>
        <div className="icd102FirstFoldContainerRightWebinarInfo2">
        <span style={{paddingLeft:"10px",textAlign:"left",marginTop:"5px",color:"#6664DA"}}>FY 2024 ICD-10-PCS Updates</span>
       
        </div>
        <div className="horizontalLineWebinarInfo"></div>
        <div className="icd102FirstFoldContainerRightWebinarInfo2">
        <span style={{paddingLeft:"10px",textAlign:"left",marginTop:"5px",color:"#6664DA"}}>Mastering Billing and Auditing for Transitional Care Management</span>
       
        </div>
        <div className="horizontalLineWebinarInfo"></div>
        <div className="icd102FirstFoldContainerRightWebinarInfo2">
        <span style={{paddingLeft:"10px",textAlign:"left",marginTop:"5px",color:"#6664DA"}}>Modifier 59 and X Modifierst</span>
       
        </div>
        <div className="horizontalLineWebinarInfo"></div>

        </div>
      </div>
      <div className="footerSDOH">
      <Footer />
    </div>
    </div>
  );
};

export default ICD102;
