import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import "./Agenda.css";
const Agenda = ({ closeModal }) => {
  useEffect(() => {
    document.body.style.overflowY = "hidden";

    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, []);

  return createPortal(
    <>
      <div className="modalWrapper" onClick={closeModal}></div>
      <div className="ModalContainer">
        <div className="ModalConatinerHeadingDiv">
          <h1 className="ModalConatinerHeading">
            Outline for a training curriculum on SDOH coding:
          </h1>
        </div>
        <div className="AgendaDeatilsConatiner">
          <p className="AgendaDeatilsHeadings">1. Introduction</p>
          <p className="AgendaDetailPoints">
            {" "}
            - Welcome and overview of the webinar objectives and agenda
            <br />- Define SDOH and explain their importance for health care
            quality and equity
          </p>
          <p className="AgendaDeatilsHeadings">
            {" "}
            2. The Coding Professional's Role in SDOH Data Capture and Reporting
          </p>
          <p className="AgendaDetailPoints">
            {" "}
            - Explain the benefits of coding for SDOH for patients, providers,
            payers and policymakers
            <br />
            - Describe the challenges and barriers to coding for SDOH
            <br />
            - Discuss the ethical and legal implications of coding for SDOH
            <br />- Share tips and resources for staying updated on SDOH coding
            standards and guidelines
          </p>
          <p className="AgendaDeatilsHeadings">
            3. The 5 Key Areas of SDOH and Their Corresponding Codes
          </p>
          <p className="AgendaDetailPoints">
            {" "}
            - Introduce the 5 domains of SDOH according to the Healthy People
            2030 framework: Economic Stability, Education Access and Quality,
            Health Care Access and Quality, Neighborhood and Built Environment,
            and Social and Community Context
            <br />
            - Provide examples of SDOH factors within each domain and their
            corresponding codes in ICD-10-CM, Z codes or other sources
            <br />- Demonstrate how to use the ICD-10-CM Official Guidelines for
            Coding and Reporting, the Coding Clinic for ICD-10-CM/PCS, the AHIMA
            Standards of Ethical Coding, and other authoritative references for
            SDOH coding
          </p>

          <p className="AgendaDeatilsHeadings">
            5. Where to Locate SDOH in the Medical Record and How to Code Them
            Accurately and Consistently
          </p>
          <p className="AgendaDetailPoints">
            {" "}
            - Review the sources of information on SDOH in the medical record,
            such as history, physical examination, assessment, plan, progress
            notes, discharge summary, etc.
            <br />
            - Identify the key elements of documentation that support SDOH
            coding, such as signs and symptoms, diagnoses, interventions,
            outcomes, referrals, etc.
            <br />
            - Apply the general coding principles and rules to SDOH coding
            scenarios and cases
            <br />- Practice coding for SDOH using real-life examples from
            different settings and specialties
          </p>

          <p className="AgendaDeatilsHeadings">6. Conclusion</p>
          <p className="AgendaDetailPoints">
            {" "}
            - Summarize the main points and takeaways from the webinar
            <br />- Provide a list of additional resources and references on
            SDOH coding
          </p>
        </div>
      </div>
    </>,
    document.querySelector(".MyModalDiv")
  );
};

export default Agenda;
