import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { AiOutlineMenu} from "react-icons/ai";
import { useState } from "react";
const Navbar = () => {

  const [clickMenu, setClickMenu] = useState(false);
  return (
    <div>
    <div className="navbarComponentContainer">
      <div className="navItem navItemLogo"> <Link to='/' className="becpmeASpeakerRoute">LMSRCM</Link></div>
     
      <div className="navItem navItemWebinars">
      <button className="menuClickNavbar" onClick={()=>setClickMenu(!clickMenu)}><AiOutlineMenu/></button>
  
      </div>
      
   
    </div>
    {clickMenu && (
      <div className="hamburgerMenu">
      <div className="hamburgerWebinars">Webinars</div>
      <div className="hamburgerWebinars">LMS demo</div>
      <Link to="/contactUs" className="hamburgerWebinarsLink"><div className="hamburgerWebinars">Become a speaker</div></Link>
    

      <div className="hamburgerSignUpButton"><Link to='/signup'><button className="signUpButtonInNavabarHamburgerMenu">Login</button></Link></div>
      
      </div>
    )}
    </div>
  );
};

export default Navbar;
