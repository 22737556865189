import React from "react";
import "./Signup.css";
const Signup = () => {
  return (
    <div className="signupContainer">
      <div className="loginSignupHeading">
        <h1 className="LoginHeading">
          {" "}
          <button className="loginButtonOnSignupPage">Login
        </button>
        <div className="backgroundColorForButton"></div>


        </h1>
        <span>|</span>
        <h1 className="SignupHeading">
          <button className="loginButtonOnSignupPage">Sign Up
          </button>
          <div className="backgroundColorForButton"></div>

        </h1>
        <div className="loginSignupFormContainer"></div>
      </div>
      <div className="loginFormContainer">
        <div className="actualloginFormContainer">
          <div className="loginFormheading">Log in to lmsrcm.com</div>
          <div className="emailAddressSection">
            <p className="emailAddressHeading">Email Address:</p>
            <input
              type="text"
              placeholder="Enter Your email address"
              className="emailAddressInput"
            />
          </div>
          <div className="emailAddressSection">
          <p className="emailAddressHeading">Password:</p>
          <input
            type="text"
            placeholder="Enter Your password"
            className="emailAddressInput"
          />
          
        </div>
        <div className="loginButtonToActuallyLogInDiv"><button className="loginButtonToActuallyLogInButton">Login</button></div>
        <div className="forgetPasswordAndSignupSection">
        <a>Forgot Password?</a>
        <a>Don't have an account, Sign Up</a>
        </div>
        </div>
      </div>
      <div className="backgroundColorForForm"></div>
    </div>
  );
};

export default Signup;
