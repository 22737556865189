import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import "./Homepage.css";
import typed from "typed.js";
import { useEffect } from "react";
import Typed from "typed.js";
import Screenshots from "../components/Screenshots";
import { useRef } from "react";
import ContactUS from "../components/ContactUS";
import Footer from "../components/Footer";
import WebinarSlider from "../components/WebinarSlider";
import BlogSlider from '../components/Blogslider';
import NewwebinarSlider from "../components/NewwebinarSlider";
import Specialities from "../components/Specialities";
import SpecialitiesAndTrainingsContainer from "../components/SpecialitiesAndTrainingsContainer";
import ChatGPTWebinarBanner from "../components/ChatGPTWebinarBanner";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
const Homepage = () => {
  // create ref element

  const el = useRef(null);
  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [
        "Learning Management System",
        "Webinar Trainings",
        "Enterprise Specific Trainings",
      ],
      startDelay: 500,
      typeSpeed: 50,
      backDelay: 1000,
      backSpeed: 0,
      showCursor: false,
      loop: true,
    });
  });
  return (
    <div className="parentForHomePageContainer">
    <div className="navbarContainer">
    <Navbar />
  </div>

      <div className="homepageContainer">
        <div className="heroSectionContainer">
       
          <div className="mainHeroSection">
            <div className="leftMainHeroSection">
             <h1 className="missionStatement">
                Effecient revenue cycle management starts with our:{" "}
              </h1>
  <span ref={el} className="typingSpan"></span>
            </div>
          </div>
        </div>
      </div>
   
      <SpecialitiesAndTrainingsContainer/>

      <Screenshots />
  
  
  
      
      
      <Footer />
    </div>
  );
};

export default Homepage;
